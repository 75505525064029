import React, { useEffect } from 'react';
import './App.css';
import SearchPlaces from './SearchPlaces';
import About from './About';
import Contact from './Contact';
import NavLink from './NavLink';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { DialogProvider } from './components/dialog/DialogContext';
import { UserPreferenceProvider } from './UserPreferenceContext';
import { BlogPost, Blog } from './BlogPost';
import { Notification } from './components/Notification';

function Redirect() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate('/search', { replace: true });
  }, [navigate]);

  return null;
}

function App() {

  document.title = 'WITW'

  return (
    <UserPreferenceProvider>
      <DialogProvider>
        <Router>
          <div className="App">
            <h1 className="mainTitle" >Where Is The Weather</h1>
            <br></br>
            <nav className="nav-menu">
              <NavLink to="/about">About</NavLink>
              <NavLink to="/search">Search</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <NavLink to="/blog">Blog</NavLink>
            </nav>
            <br></br>
            <Notification/>
            <br></br>
            <Routes>
              <Route path="/" element={<Redirect />} />
              <Route path="/about" element={<About />} />
              <Route path="/search" element={<SearchPlaces />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:postId" element={<BlogPost />} />
            </Routes>
          </div>
        </Router>
      </DialogProvider>
    </UserPreferenceProvider>
  );
}

export default App;
