import React from 'react';
import headshot from './LiamHeadshot.png';
import './Contact.css';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

function Contact() {

    return (
        <div className="contactContainer">
            <Helmet>
                <title>Contact | Where Is The Weather</title>
                <meta name="description" content="Get in touch with WITW for any inquiries or support." />
                <link rel="canonical" href="https://www.whereistheweather.com/contact" />
            </Helmet>
            <h1 className='contactSectionHeader'>Contact Me</h1>
            <div className="bioSection">
                <img src={headshot} className="bioImage" alt="Liam Hawtin's headshot" />
                <div className="contactDetails">
                    <div className="email">
                        <a href="mailto:liamhawtin@gmail.com">
                            <FontAwesomeIcon icon={faEnvelope} size="2x" />
                            liamhawtin@gmail.com
                        </a>
                    </div>
                    <div className="linkedin">
                        <a href="https://www.linkedin.com/in/liam-h-72429111b/">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                            Liam Hawtin
                        </a>
                    </div>
                </div>
            </div>
            <div className="bioText">
                Hi, my name is Liam, and I am the developer behind this website and project.
                <br /><br />
                I appreciate any feedback or suggestions that you may have, so please don't hesitate to get in touch. You can contact me via email at <a href="mailto:liamhawtin@gmail.com">liamhawtin@gmail.com</a> or through <a href="https://www.linkedin.com/in/liam-h-72429111b/">LinkedIn</a>.
            </div>
        </div>
    );
}

export default Contact;
