import React from 'react';

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        border: 'none',
        borderCollapse: 'collapse',
        width: '70%',
        textAlign: 'center',
        fontSize: '0.9rem'
    },
    cell: {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRight: 'none',
        padding: '5px',
    }
};

const getCellStyle = (rowIndex, cellIndex, data) => {
    let style = { ...styles.cell };

    if (rowIndex === 0) {
        style.borderTop = 'none';
    }

    if (rowIndex === data.length - 1) {
        style.borderBottom = 'none';
    }

    if (cellIndex === 0) {
        style.borderLeft = 'none';
    }

    return style;
}

function processData(inputArray) {
    let output = [];
    for (let i = 0; i < inputArray.length; i += 2) {
        let pair = [inputArray[i], i + 1 < inputArray.length ? inputArray[i + 1] : ''];
        output.push(pair);
    }
    return output;
}

const DataTable = ({ list }) => {
    const data = processData(list)
    return (
        <div style={styles.wrapper}>
            <table style={styles.table}>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td
                                    key={cellIndex}
                                    style={getCellStyle(rowIndex, cellIndex, data)}
                                >
                                    {cell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DataTable;
