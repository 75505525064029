import React, { useRef, useEffect } from 'react';
import styles from './css/CanvasOverlay.module.css';

const CanvasOverlay = ({ isMoveMode, brushRadius, mode, onUndo, onRedo, onCanvasRef }) => {
    const canvasRef = useRef(null);
    const drawingHistoryRef = useRef([]);
    const historyIndexRef = useRef(-1);
    const customCursorRef = useRef(null);

    const saveToHistory = (imageData) => {
        drawingHistoryRef.current = drawingHistoryRef.current.slice(0, historyIndexRef.current + 1);
        drawingHistoryRef.current.push(imageData);
        historyIndexRef.current++;
    };

    useEffect(() => {
        const customCursor = customCursorRef.current;
        const canvas = canvasRef.current;

        if (mode === "add" || mode === undefined) {
            customCursor.style.backgroundColor = 'rgba(0, 128, 0, 1)';
        } else {
            customCursor.style.backgroundColor = 'rgba(128, 0, 0, 1)';
        }

        const updateCursor = (e) => {
            const x = e.clientX;
            const y = e.clientY;
            customCursor.style.left = `${x - brushRadius / 2}px`;
            customCursor.style.top = `${y - brushRadius / 2}px`;
            customCursor.style.width = `${brushRadius}px`;
            customCursor.style.height = `${brushRadius}px`;
        };

        const hideCursor = () => {
            customCursor.style.display = 'none';
        };

        const showCursor = () => {
            customCursor.style.display = 'block';
        };

        canvas.addEventListener('mousemove', updateCursor);
        canvas.addEventListener('mouseleave', hideCursor);
        canvas.addEventListener('mouseenter', showCursor);

        return () => {
            canvas.removeEventListener('mousemove', updateCursor);
            canvas.removeEventListener('mouseleave', hideCursor);
            canvas.removeEventListener('mouseenter', showCursor);
        };
    }, [brushRadius, mode]);




    useEffect(() => {
        const mapContainer = document.querySelector('.leaflet-container');
        const initialRect = mapContainer.getBoundingClientRect();

        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const newTop = initialRect.top - currentScrollY;
            if (canvasRef.current) {
                canvasRef.current.style.top = `${newTop}px`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isMoveMode && canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvasRef.current.getContext('2d');
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            drawingHistoryRef.current = [];
            historyIndexRef.current = -1;
            saveToHistory(context.getImageData(0, 0, canvas.width, canvas.height));
        }
    }, [isMoveMode]);

    useEffect(() => {
        const resizeCanvas = () => {
            const mapContainer = document.querySelector('.leaflet-container');
            if (mapContainer && canvasRef.current) {
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');

                // Store the current image data before resizing
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

                // Store the current context settings
                const oldStrokeStyle = context.strokeStyle;
                const oldLineWidth = context.lineWidth;
                const oldGlobalCompositeOperation = context.globalCompositeOperation;

                // Resize the canvas
                const rect = mapContainer.getBoundingClientRect();
                canvas.width = rect.width;
                canvas.height = rect.height;
                canvas.style.top = `${rect.top}px`;
                canvas.style.left = `${rect.left}px`;

                // Restore the context settings
                context.strokeStyle = oldStrokeStyle;
                context.lineWidth = oldLineWidth;
                context.globalCompositeOperation = oldGlobalCompositeOperation;

                // Put the image data back on the canvas
                context.putImageData(imageData, 0, 0);
            }
        };

        const handleLeafletContainerResized = () => {
            // This function is called when the leaflet-container is resized.
            resizeCanvas();
        };

        // Listen for custom event
        window.addEventListener('leaflet-container-resized', handleLeafletContainerResized);

        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            // Remove listener for custom event
            window.removeEventListener('leaflet-container-resized', handleLeafletContainerResized);
        };
    }, []);





    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        saveToHistory(context.getImageData(0, 0, canvas.width, canvas.height));
        let painting = false;

        if (mode === "add" || mode === undefined) {
            context.globalCompositeOperation = 'source-over';
            context.strokeStyle = 'rgba(0, 128, 0, 1)';
        } else {
            context.globalCompositeOperation = 'destination-out';
            context.strokeStyle = 'rgba(0, 0, 0, 1)';
        }

        const startPosition = (e) => {
            if (e.type.startsWith('touch')) {
                e.preventDefault();
            }
            painting = true;
            draw(e);
        };

        const endPosition = () => {
            painting = false;
            context.beginPath();
            saveToHistory(context.getImageData(0, 0, canvas.width, canvas.height));
        };

        const draw = (e) => {
            if (!painting) return;

            if (e.type.startsWith('touch')) {
                e.preventDefault();
            }

            let clientX, clientY;

            if (e.type.startsWith('touch')) {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            } else {
                clientX = e.clientX;
                clientY = e.clientY;
            }

            context.lineWidth = brushRadius;
            context.lineCap = 'round';

            context.lineTo(clientX - canvas.offsetLeft, clientY - canvas.offsetTop);
            context.stroke();
            context.beginPath();
            context.moveTo(clientX - canvas.offsetLeft, clientY - canvas.offsetTop);
        };

        // For Desktops
        canvas.addEventListener('mousedown', startPosition);
        canvas.addEventListener('mouseup', endPosition);
        canvas.addEventListener('mousemove', draw);

        // For Mobile devices
        canvas.addEventListener('touchstart', startPosition);
        canvas.addEventListener('touchend', endPosition);
        canvas.addEventListener('touchmove', draw);

        return () => {
            // Clean up the event listeners when the component is unmounted
            canvas.removeEventListener('mousedown', startPosition);
            canvas.removeEventListener('mouseup', endPosition);
            canvas.removeEventListener('mousemove', draw);

            canvas.removeEventListener('touchstart', startPosition);
            canvas.removeEventListener('touchend', endPosition);
            canvas.removeEventListener('touchmove', draw);
        };
    }, [brushRadius, mode]);

    useEffect(() => {
        // Pass the canvas ref to the parent component.
        if (onCanvasRef) {
            onCanvasRef(canvasRef.current);
        }
    }, [onCanvasRef]);


    useEffect(() => {
        const undo = () => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            if (historyIndexRef.current <= 0) return;
            historyIndexRef.current--;
            context.putImageData(drawingHistoryRef.current[historyIndexRef.current], 0, 0);
        };

        const redo = () => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            if (historyIndexRef.current >= drawingHistoryRef.current.length - 1) return;
            historyIndexRef.current++;
            context.putImageData(drawingHistoryRef.current[historyIndexRef.current], 0, 0);
        };

        onUndo(undo);
        onRedo(redo);

    }, [onUndo, onRedo]);

    return (
        <div className={styles.canvasWrapper}>
            <canvas
                ref={canvasRef}
                className={`${styles.canvas} ${isMoveMode ? styles.nonInteractive : ''}`}
            ></canvas>
            <div ref={customCursorRef} className={styles.customCursor}></div>
        </div>
    );

};

export default CanvasOverlay;