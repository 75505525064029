import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-WL7ENY578V"); // replace with your Measurement ID
ReactGA.send("page_view"); // or "screen_view" for apps

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);