import React, { createContext, useState, useEffect } from 'react';

export const UserPreferenceContext = createContext();

export const UserPreferenceProvider = ({ children }) => {
    const savedSystem = localStorage.getItem('system'); 
    const [system, setSystem] = useState(savedSystem || 'C'); // Default to Metric

    useEffect(() => {
        if (!savedSystem) {
            fetch('https://api.ipgeolocation.io/ipgeo?apiKey=d840f95fb5cf4221896f62c78adfa96b')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const geolocationSystem = data && data.country_code2 === 'US' ? 'F' : 'C';
                localStorage.setItem('system', geolocationSystem);
                setSystem(geolocationSystem);
            })
            .catch(error => {
                console.error('Error fetching geolocation data:', error);
                // Default to Celsius if there's an error
                localStorage.setItem('system', 'C');
                setSystem('C');
            });
        }
    }, []); // Only run this effect once on mount

    const setPreference = (pref) => {
        localStorage.setItem('system', pref);
        setSystem(pref);
    };

    return (
        <UserPreferenceContext.Provider value={{ system, setPreference }}>
            {children}
        </UserPreferenceContext.Provider>
    );
};
