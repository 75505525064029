import React, { useState, useEffect } from 'react';
import styles from './css/Toolbar.module.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BrushIcon from '@mui/icons-material/Brush';
import MapIcon from '@mui/icons-material/Map';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import Slider from '@mui/material/Slider';
import { useConfirm } from '../components/dialog/DialogHooks';

const MAX_BRUSH_AREA = 20;
const MIN_BRUSH_AREA = 2;

const Toolbar = ({ onBrushChange, onModeChange, onUndo, onRedo, onToggleDrag }) => {
  const [brushArea, setBrushArea] = useState(5);
  const [mode, setMode] = useState('add');
  const [isDraggingEnabled, setDraggingEnabled] = useState(true);
  const confirm = useConfirm();

  useEffect(() => {
    const radius = Math.sqrt(brushArea / Math.PI);
    onBrushChange && onBrushChange(radius);
  }, []);

  const handleSliderChange = (event, newValue) => {
    setBrushArea(newValue);
    const radius = Math.sqrt(newValue / Math.PI);
    onBrushChange && onBrushChange(radius);
  };

  const handleInputChange = (event) => {
    const newArea = event.target.value;
    setBrushArea(newArea);
    const radius = Math.sqrt(newArea / Math.PI);
    onBrushChange && onBrushChange(radius);
  };

  const toggleMode = () => {
    const newMode = mode === 'add' ? 'subtract' : 'add';
    setMode(newMode);
    onModeChange && onModeChange(newMode);
  };

  const toggleDrag = async () => {
    if (isDraggingEnabled || await confirm("Are you sure you want to discard this?")) {
      const newDraggingState = !isDraggingEnabled;
      setDraggingEnabled(newDraggingState);
      onToggleDrag && onToggleDrag(newDraggingState);
    }
  };

  return (
    <div className={styles.toolbar}>
      <button className={styles.toggleDragButton} onClick={toggleDrag}>
        {isDraggingEnabled ? <BrushIcon /> : <MapIcon />}
      </button>

      <div className={styles.sliderContainer} disabled={isDraggingEnabled}>
        <Slider
          value={brushArea}
          onChange={handleSliderChange}
          min={MIN_BRUSH_AREA}
          max={MAX_BRUSH_AREA}
          className={styles.slider}
          disabled={isDraggingEnabled}
        />
        <input
          type="number"
          value={brushArea}
          min={MIN_BRUSH_AREA}
          max={MAX_BRUSH_AREA}
          onChange={handleInputChange}
          className={styles.numberInput}
          disabled={isDraggingEnabled}
        />
      </div>

      <button className={styles.modeButton} onClick={() => toggleMode()} disabled={isDraggingEnabled}>
        {mode === 'add' ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
      </button>

      <button className={styles.button} onClick={() => onUndo()} disabled={isDraggingEnabled}>
        <UndoIcon />
      </button>

      <button className={styles.button} onClick={() => onRedo()} disabled={isDraggingEnabled}>
        <RedoIcon />
      </button>

    </div>
  );
};

export default Toolbar;
