import React, { useState } from 'react';

const RegionSearch = ({ styles, getComboboxProps, getInputProps, getMenuProps, getItemProps, setInputFocused, inputFocused, loading, inputItems, highlightedIndex, setHighlightedIndex }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleClose = (event) => {
        event.stopPropagation();
        setInputFocused(false);
        setMenuOpen(false);
    };

    return (
        <div className={styles.searchContainer}>
            <div {...getComboboxProps}>
                <input
                    {...getInputProps({ className: styles.searchInput })}
                    placeholder="Search Regions (native language)"
                    onFocus={() => {
                        setInputFocused(true);
                        setMenuOpen(true);
                    }}
                    onBlur={() => {
                        if (!loading) {
                            setTimeout(() => setInputFocused(false), 200);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                            e.preventDefault();
                        } else if (!menuOpen) {
                            setInputFocused(true);
                            setMenuOpen(true)
                        }
                    }}
                />
            </div>
            {menuOpen && (
                <ul
                    {...getMenuProps({
                        className: `${styles.dropdown} ${inputFocused ? styles.open : ""}`,
                    })}
                >
                    {loading ? (
                        <li className={styles.dropdownItem}>Loading...</li>
                    ) : (
                        <>
                            {inputItems.map((item, index) => (
                                <li
                                    className={styles.dropdownItem}
                                    style={highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}}
                                    key={`${item.id}${index}`}
                                    {...getItemProps({ 
                                        item, 
                                        index,
                                        onClick: handleClose
                                    })}
                                >
                                    {`${item.tags["wikipedia"] ? item.tags["wikipedia"].split(':')[1] : item.tags["name"]}${item.extra ? ` (${item.extra})` : ''}`}
                                </li>
                            ))}
                        </>
                    )}
                    <li
                        className={styles.closeButton}
                        onClick={handleClose}
                    >
                        Close
                    </li>
                </ul>
            )}
        </div>
    );
};

export default RegionSearch;
