import axios from "axios";
import { overpassUrl, calculateDistance } from './utils';


const oneWeek = 7 * 24 * 60 * 60 * 10000;
const twoHours = 2 * 60 * 60 * 10000;

// Key generators
const generateKeyBeaches = (center, radius) => {
    const roundedX = Math.round(center.x * 10000) / 10000;
    const roundedY = Math.round(center.y * 10000) / 10000;
    return `Beaches:${roundedX}:${roundedY}:${radius}`;
};

const generateKeyWeather = (lat, lon, currentDate) => {
    const roundedLat = Math.round(lat * 10000) / 10000;
    const roundedLon = Math.round(lon * 10000) / 10000;
    return `Weather:${roundedLat}:${roundedLon}:${currentDate}`;
};

/*
const generateKeyWaterBodies = (lat, lon) => {
    const roundedLat = Math.round(lat * 10000) / 10000;
    const roundedLon = Math.round(lon * 10000) / 10000;
    return `WaterBodies:${roundedLat}:${roundedLon}`;
};
*/

const generateKeyItems = (input) => {
    return `Items:${input}`;
};

const generateKeyBoundaries = (id) => `Boundaries:${id}`;

// Cache handlers
const getFromCache = (key, expiry) => {
    const cachedData = localStorage.getItem(key);

    if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);

        if (Date.now() - timestamp < expiry) {
            return data;
        } else {
            localStorage.removeItem(key);
        }
    }

    return null;
};

// Priority list
const priorityList = {
    "Weather": 3,
    "WaterBodies": 2,
    "Beaches": 1,
    "Items": 1,
    "Boundaries": 1
}

// Get priority from key
const getPriority = (key) => {
    const category = key.split(":")[0];
    return priorityList[category] || 0;
}

// Check if there's enough space in the local storage
const checkStorageSpace = (size) => {
    let allKeys = Object.keys(localStorage);
    let spaceUsed = allKeys.reduce((acc, currKey) => acc + localStorage.getItem(currKey).length, 0);
    let totalSpace = 2 * 1024 * 1024; // Approx 5MB, standard quota for most web browsers
    let remainingSpace = totalSpace - spaceUsed;
    return remainingSpace > size;
}

const evictFromCache = (newDataPriority) => {
    let allKeys = Object.keys(localStorage);
    // Sort the keys based on the priority (in ascending order)
    allKeys.sort((a, b) => getPriority(a) - getPriority(b));

    for (let key of allKeys) {
        // Remove the item from cache if its priority is lower or equal to the new data
        if (getPriority(key) <= newDataPriority) {
            localStorage.removeItem(key);
            return;
        }
    }
}

const saveToCache = (key, data) => {
    const itemString = JSON.stringify({ data: data, timestamp: Date.now() });

    // if there is not enough space in local storage
    if (!checkStorageSpace(itemString.length)) {
        // Evict the least priority item from cache
        evictFromCache(getPriority(key));
        // After eviction, check if there is enough space
        if (!checkStorageSpace(itemString.length)) {
            // If still there is not enough space, return without saving to cache
            return;
        }
    }
    localStorage.setItem(key, itemString);
};


// API calls
const fetchBeaches = async (center, radius) => {
    const tags = ['"natural"="beach"', '"leisure"="swimming_area"'];
    let beaches = [];

    const key = generateKeyBeaches(center, radius);
    const cachedBeaches = getFromCache(key, oneWeek);

    if (cachedBeaches) {
        return cachedBeaches;
    }

    const timeout = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('timeout')), 20 * 1000)
    );

    for (let tag of tags) {
        const query = `[out:json];(node[${tag}](around:${radius},${center.y},${center.x});way[${tag}](around:${radius},${center.y},${center.x});relation[${tag}](around:${radius},${center.y},${center.x}););out geom;`;

        try {
            const response = await Promise.race([
                fetch(overpassUrl(query)),
                timeout
            ]);

            const data = await response.json();

            // Check for 'remark' field in the response data
            if (data.remark && data.remark.startsWith("runtime error")) {
                // Handle error
                return { error: "Search area is too big" };
            }

            beaches = beaches.concat(data.elements);

        } catch (error) {
            if (error.message === 'timeout') {
                return { error: "Search took too long. The search area might be too big or there may be another issue." };
            } else {
                return { error: "An unexpected error occurred" };
            }
        }
    }

    // Save to cache before returning
    //saveToCache(key, beaches);

    return beaches;
};

const fetchWeather = async (beaches) => {
    const weatherPromises = beaches.map(async (beach) => {
        const lat = beach.center.lat;
        const lon = beach.center.lon;

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const encodedTimezone = encodeURIComponent(timezone);
        const url = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&hourly=temperature_2m,precipitation_probability,precipitation,cloudcover,windspeed_10m,winddirection_10m,windgusts_10m,shortwave_radiation&timezone=${encodedTimezone}`;

        const currentDate = new Date().toLocaleDateString();
        const key = generateKeyWeather(lat, lon, currentDate);
        const cachedWeather = getFromCache(key, twoHours);

        if (cachedWeather) {
            return cachedWeather;
        }

        const res = await axios.get(url);
        const weather = res.data;

        // Save to cache before returning
        if (weather !== null) {
            saveToCache(key, weather);
        }

        return weather;
    });

    // Use Promise.all to wait for all requests to complete before continuing.
    const allWeatherData = await Promise.all(weatherPromises);

    // Update weather data for each beach.
    for (let i = 0; i < beaches.length; i++) {
        beaches[i].weather = allWeatherData[i];
    }
};

const getNearbyWaterBodiesForBatch = async (beachBatch) => {
    // Calculate the center of all beach points
    const centerLat = beachBatch.reduce((acc, beach) => acc + beach.center.lat, 0) / beachBatch.length;
    const centerLon = beachBatch.reduce((acc, beach) => acc + beach.center.lon, 0) / beachBatch.length;

    // Calculate the maximum distance to any beach from the center
    const distances = beachBatch.map(beach => calculateDistance(centerLat, centerLon, beach.center.lat, beach.center.lon));
    const maxCalculatedDistance = Math.max(...distances);
    const maxDistance = Math.max(maxCalculatedDistance, 1000);

    const overpassQuery = `
      [out:json];
      (
        rel["natural"="bay"](around:${maxDistance},${centerLat},${centerLon});
        way["natural"="coastline"](around:${maxDistance},${centerLat},${centerLon});
        rel["natural"="water"](around:${maxDistance},${centerLat},${centerLon});
        way["natural"="water"](around:${maxDistance},${centerLat},${centerLon});
        way["waterway"="river"](around:${maxDistance},${centerLat},${centerLon});
        rel["place"="sea"](around:${maxDistance},${centerLat},${centerLon});
      );
      out geom;
    `;

    const url = overpassUrl(overpassQuery);
    const response = await axios.get(url);

    const waterBodies = response.data.elements;

    return waterBodies;
};

const fetchAndCacheItems = async (input, cancelToken) => {
    const key = generateKeyItems(input);
    const cachedItems = getFromCache(key, oneWeek);

    if (cachedItems) {
        return cachedItems;
    }

    const overpassQuery = `
        [out:json][timeout:25];
        (
          relation["name"~"${input}", i]["type"="boundary"];
          relation["name"~"${input}", i]["type"="multipolygon"];
        );
        out tags 100;
    `;

    const res = await axios.get(overpassUrl(overpassQuery), { cancelToken });

    let items = null;
    if (res.data.elements) {
        // Similar filtering logic to the original fetchItems function
        let filteredItems = res.data.elements.filter(item => {
            const tags = item.tags || {};
            const adminLevel = parseInt(tags.admin_level, 10);
            const place = tags.place;
            return (
                (adminLevel >= 2 && adminLevel <= 7) ||
                ["city", "village", "state", "town", "island"].includes(place)
            );
        });

        // Add 'extra' attribute to each filtered item
        items = filteredItems.map(item => {
            const tags = item.tags || {};
            if (tags.border_type) {
                return { ...item, extra: tags.border_type };
            } else if (tags.admin_level === "2") {
                return { ...item, extra: "country" };
            } else {
                return { ...item, extra: tags.place || '' };
            }
        });

        // If too many results, treat it as no results
        if (items.length > 50) {
            items = [];
        }

        // Save to cache before returning
        //saveToCache(key, items);
    }

    return items;
};

const fetchBoundaries = async (id) => {
    const key = generateKeyBoundaries(id);
    const cachedBoundaries = getFromCache(key, oneWeek);

    if (cachedBoundaries) {
        return cachedBoundaries;
    }

    let query = `[out:json];relation(${id});out geom;`;
    const res = await axios.get(overpassUrl(query));

    const data = {
        members: null,
        bounds: null,
        status: "failure"
    };

    if (res.data.elements.length > 0) {
        data.members = res.data.elements[0].members;
        data.bounds = res.data.elements[0].bounds;
        data.status = data.members ? "success" : "failure";
    }

    // Save to cache before returning
    //saveToCache(key, data);

    return data;
};


export { fetchWeather, fetchBeaches, getNearbyWaterBodiesForBatch, fetchAndCacheItems, fetchBoundaries, generateKeyWeather, getFromCache}