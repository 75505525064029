import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import Toolbar from './Toolbar';
import styles from './css/Map.module.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import CanvasOverlay from './CanvasOverlay';
import LocationSearch from './LocationSearch';
import { createPolygonsWithHoles } from '../utils/MapBoundaryAlgorithms'
import { useConfirm } from '../components/dialog/DialogHooks';

const SetViewOnInit = ({ center, zoom }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(center, zoom);
    }, []); // Empty dependency array ensures this runs only once on mount
    return null;
};


const ToggleDrag = ({ isEnabled }) => {
    const map = useMap();
    useEffect(() => {
        if (isEnabled) {
            map.dragging.enable();
            map.scrollWheelZoom.enable();
            map.doubleClickZoom.enable();
            map.boxZoom.enable();
            map.keyboard.enable();
            map.touchZoom.enable()
        } else {
            map.dragging.disable();
            map.scrollWheelZoom.disable();
            map.doubleClickZoom.disable();
            map.boxZoom.disable();
            map.keyboard.disable();
            map.touchZoom.disable()
        }
    }, [isEnabled, map]);
    return null;
};


const Map = ({ discard, selectedItems, setSelectedItems }) => {
    const centerPosition = [51.505, -0.09];
    const initialZoom = 13;
    const [isMoveMode, setIsMoveMode] = useState(true);
    const [brushRadius, setBrushRadius] = useState(10);
    const [mode, setMode] = useState('add');
    const [inputValue, setInputValue] = useState('Unnamed region');
    const [isMapResized, setIsMapResized] = useState(false);
    const [loadingBoundary, setLoadingBoundary] = useState(false);
    const confirm = useConfirm();

    const mapRef = useRef(null);

    const undoCallbacks = useRef([]);
    const redoCallbacks = useRef([]);

    const canvasRefInMap = useRef(null);

    const handleLocationSelect = (lat, lon) => {
        if (mapRef.current) {
            const latlng = L.latLng(lat, lon);
            mapRef.current.setView(latlng, 15); // Fly to the selected location with a zoom level of 15
        }
    };

    const handleCanvasRef = useCallback((canvasRef) => {
        canvasRefInMap.current = canvasRef;
    }, []);

    const handleToggleDrag = () => {
        setIsMoveMode(!isMoveMode);
    };

    const handleBrushChange = (radius) => {
        setBrushRadius(radius * 20);
    };

    const handleModeChange = (newMode) => {
        setMode(newMode);
    };

    const triggerUndo = () => {
        undoCallbacks.current.forEach(cb => cb());
    };

    const triggerRedo = () => {
        redoCallbacks.current.forEach(cb => cb());
    };

    const handleUndo = useCallback((callback) => {
        undoCallbacks.current.push(callback);
    }, []);

    const handleRedo = useCallback((callback) => {
        redoCallbacks.current.push(callback);
    }, []);

    const onDiscard = async () => {

        if (await confirm("Are you sure you want to discard this?")) {
            discard()
        }
    }

    const calculateHeight = (width, aspectRatio) => {
        return `${width / aspectRatio}px`;
    };

    const setMapHeight = () => {
        // Getting the map container DOM element
        const mapContainer = mapRef.current ? mapRef.current.getContainer() : null;
        if (mapContainer) {
            const width = mapContainer.offsetWidth;

            let aspectRatio = 16 / 9; // Default aspect ratio
            if (window.matchMedia('(max-width: 600px)').matches) {
                // If the screen width is 600px or less, adjust the aspect ratio
                aspectRatio = 16 / 12;
            }

            const height = width / aspectRatio;
            mapContainer.style.height = `${height}px`; // Set the height

            // Optional: Call Leaflet's invalidateSize method
            if (mapRef.current) {
                mapRef.current.invalidateSize();
            }
        }
        const event = new CustomEvent('leaflet-container-resized');
        window.dispatchEvent(event);
    };


    useLayoutEffect(() => {
        // Call once on initial load
        setTimeout(setMapHeight, 0);

        // Then setup listener for future resizes
        window.addEventListener('resize', setMapHeight);

        // Step 2: Add event listener for the custom event
        const handleMapResized = () => setIsMapResized(true);
        window.addEventListener('leaflet-container-resized', handleMapResized);

        // Cleanup
        return () => window.removeEventListener('leaflet-container-resized', handleMapResized);

    }, []);



    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.ctrlKey && e.key === 'z') {
                undoCallbacks.current.forEach(cb => cb());
            }
            if (e.ctrlKey && e.key === 'y') {
                redoCallbacks.current.forEach(cb => cb());
            }
        });
    }, []);

    const save = async () => {

        if (selectedItems.some(selectedItem => selectedItem.name === inputValue)) {
            await confirm(`A region with the name '${inputValue}' already exists`)
            return
        }

        setLoadingBoundary(true)

        const context = canvasRefInMap.current.getContext('2d');

        // Get canvas dimensions
        const width = canvasRefInMap.current.width;
        const height = canvasRefInMap.current.height;

        // Get the image data
        const imageData = context.getImageData(0, 0, width, height);

        const bounds = mapRef.current.getBounds();

        const mapBounds = [[bounds._northEast.lat, bounds._southWest.lng], [bounds._southWest.lat, bounds._northEast.lng]];

        const customRegionWorker = new Worker(new URL('../workers/customRegionWorker.js', import.meta.url));

        customRegionWorker.onmessage = async (event) => {

            if (event.data.statusComplete) {
                const mapContours = event.data.mapContours;
                const polygons = createPolygonsWithHoles(mapContours)
                const newSelectedItem = { inputType: "drawn", name: inputValue, geometry: polygons }
                setSelectedItems([...selectedItems, newSelectedItem])
                setLoadingBoundary(false)
                discard()
            }
        };

        customRegionWorker.postMessage({ imageData, mapBounds, width, height, inputValue });

    }

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.saveContainer}>
                <input
                    className={styles.saveInput}
                    placeholder={inputValue}
                    value={inputValue} // This binds the input value to the state
                    onChange={(event) => setInputValue(event.target.value)} // This sets the event handler for input changes
                />
                <div className={styles.saveButtonGroup}>
                    <button className={styles.saveButton} onClick={save} disabled={loadingBoundary}>Save</button>
                    <button className={styles.discardButton} onClick={() => onDiscard()}>Discard</button>
                </div>
            </div>
            <div className={styles.instruction}>
                Activate the brush below to draw your region and then save
            </div>
            <MapContainer
                ref={mapRef} // Add ref here
                className={styles.map}
                zoomControl={false}
                center={centerPosition}
                zoom={initialZoom}
                style={{ height: calculateHeight(window.innerWidth, 16 / 9) }} // Set initial height inline
                whenCreated={(map) => (mapRef.current = map)} // Add whenCreated here
            >
                <SetViewOnInit center={centerPosition} zoom={initialZoom} />
                <ToggleDrag isEnabled={isMoveMode} />
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    attribution="&copy; CartoDB"
                />
            </MapContainer>
            {isMapResized && isMoveMode && (
                <div
                    style={{
                        position: 'absolute',
                        top: mapRef.current ? mapRef.current.getContainer().offsetTop + 10 : 0,
                        left: '10px',
                        zIndex: 1000,
                        width: '50%',
                    }}
                    onWheel={(e) => e.stopPropagation()}
                >
                    <LocationSearch onLocationSelect={handleLocationSelect} />
                </div>
            )}
            <CanvasOverlay
                isMoveMode={isMoveMode}
                brushRadius={brushRadius}
                mode={mode}
                onUndo={handleUndo}
                onRedo={handleRedo}
                onCanvasRef={handleCanvasRef}
            />
            <Toolbar
                onToggleDrag={handleToggleDrag}
                onBrushChange={handleBrushChange}
                onModeChange={handleModeChange}
                onUndo={triggerUndo}
                onRedo={triggerRedo}
            />
        </div>
    );
};

export default Map;

