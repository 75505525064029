import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './BlogPost.module.css';
import { marked } from 'marked';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';

const renderer = new marked.Renderer();

renderer.image = (href, title, text) => {
    return `<img src="${href}" alt="${text}" class="${styles.postImage}" style="--image-url: url(${href})"/>`;
};

renderer.heading = (text, level) => {
    return `<h${level} class="${styles[`h${level}`]}">${text}</h${level}>`;
};

renderer.paragraph = (text) => {
    return `<p class="${styles.postParagraph}">${text}</p>`;
};

marked.setOptions({ renderer });

function BlogPost() {

    const { postId } = useParams();
    const [postContent, setPostContent] = useState("");

    useEffect(() => {
        fetch(`/blog_posts/${postId}.md`)
            .then(response => response.text())
            .then(text => setPostContent(marked(text)));
    }, [postId]);

    return (
        <div
            className={styles.postContainer}
            dangerouslySetInnerHTML={{ __html: postContent }}
        />
    );
}

function Blog() {

    document.title = 'Blog | Where Is The Weather'

    const posts = [
        {
            id: 'How_to_Use_the_Website',
            title: 'How to Use the Website',
            description: 'A guide on how to use this website.',
            image: '/path/to/your/image.jpg'
        },
        // ... add more posts here
    ];

    return (
        <div className={styles.postContainer}>
            <h1>Blog</h1>
            {posts.map((post, index) => (
                <Card className={styles.postCard} key={index}>
                    <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {post.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {post.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Link>
                </Card>
            ))}
        </div>
    );
}

export { BlogPost, Blog };
