import React, { useState, useEffect } from 'react';
import styles from './css/LocationSearch.module.css';

const LocationSearch = ({ onLocationSelect }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        return () => {
            // Cleanup: Clear the timer when the component is unmounted
            clearTimeout(timer);
        };
    }, [timer]);

    const fetchData = async (value) => {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${value}`);
        const data = await response.json();
        setResults(data);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);

        // Clear the timer if it's already set
        if (timer) {
            clearTimeout(timer);
        }

        // Set a new timer
        setTimer(
            setTimeout(() => {
                if (value.length > 2) {
                    fetchData(value);
                } else {
                    setResults([]);
                }
            }, 500) // 500ms delay
        );
    };

    const handleItemClick = (lat, lon) => {
        if (onLocationSelect) {
            onLocationSelect(lat, lon);
        }
        setResults([]); // Clear the suggestions
    };

    return (
        <div className={styles.LocationSearch}>
            <input
                type="text"
                placeholder="Search..."
                value={query}
                onChange={handleInputChange}
                className={styles.LocationSearchInput}
            />
            {results.length > 0 && (
                <ul className={styles.LocationSearchResults}>
                    {results.map((result) => (
                        <li
                            key={result.place_id}
                            onClick={() => handleItemClick(result.lat, result.lon)}
                            className={styles.LocationSearchItem}
                        >
                            {result.display_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

};

export default LocationSearch;
