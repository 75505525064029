import React, { useState, useContext } from 'react';
import {
    Button, Box, ToggleButton, ToggleButtonGroup, FormControlLabel,
    FormControl, InputLabel, Select, MenuItem, Grid, TextField, Slider, IconButton, Switch,
    Paper, Tab, Tabs
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { weatherMetrics, units, convertMeasurement, formatNumber } from '../utils/utils';
import { UserPreferenceContext } from '../UserPreferenceContext';
import useMediaQuery from '@mui/material/useMediaQuery';


const ConfigButton = ({ name, config, updateConfig }) => {
    const [values, setValues] = useState(() => {
        const selected = [];
        if (config.av) selected.push('av');
        if (config.min) selected.push('min');
        if (config.max) selected.push('max');
        return selected;
    });

    const handleCheckboxChange = (event, newValues) => {
        setValues(newValues);
        updateConfig(name, { av: newValues.includes('av'), min: newValues.includes('min'), max: newValues.includes('max') });
    };

    return (
        <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1, width: '100%', borderRadius: '0rem 0rem 0.5rem 0.5rem', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
            <Box sx={{ whiteSpace: 'nowrap', width: '100%' }}>
                <Box
                    sx={{
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        borderColor: 'transparent',
                        borderRadius: '0rem',
                        display: 'inline-block',
                        padding: '6px 16px',
                        fontSize: '0.875rem',
                        lineHeight: '1.75',
                        backgroundColor: 'theme.palette.primary.main',
                        color: 'black',
                        cursor: 'default',
                        width: '100%',
                    }}
                >
                    {config.name}
                </Box>
            </Box>
            <ToggleButtonGroup
                size="small"
                value={values}
                exclusive={false}
                onChange={handleCheckboxChange}
                sx={{ width: '100%', borderColor: 'transparent', borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}
            >
                <ToggleButton value="av" aria-label="Average" sx={{ fontSize: '0.7rem', flexGrow: 1, borderRadius: '0rem 0.5rem' }}>Av</ToggleButton>
                <ToggleButton value="min" aria-label="Min" sx={{ fontSize: '0.7rem', flexGrow: 1 }}>Min</ToggleButton>
                <ToggleButton value="max" aria-label="Max" sx={{ fontSize: '0.7rem', flexGrow: 1, borderRadius: '0.5rem 0rem' }}>Max</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );

};

const FilterComponent = ({ ranges, filters, setFilters, styles, allFilterTypes, filterTypeConfig, setFilterTypeConfig }) => {

    const matches = useMediaQuery('(min-width:600px)');

    const { system } = useContext(UserPreferenceContext);

    const unusedMetric = Object.keys(weatherMetrics).find(metric =>
        !filters.some(filter => filter.metric === metric) && metric !== 'winddirection_10m'
    );

    const selectAll = (tabKey) => {
        setFilterTypeConfig(prevConfig => ({
            ...prevConfig,
            [tabKey]: allFilterTypes[tabKey].slice() // Copy all items from the original array
        }));
    };

    const deselectAll = (tabKey) => {
        setFilterTypeConfig(prevConfig => ({
            ...prevConfig,
            [tabKey]: [] // Empty array
        }));
    };

    const addFilter = () => {
        if (unusedMetric) {
            setFilters(prevFilters => [
                ...prevFilters,
                {
                    metric: unusedMetric,
                    type: 'max',
                    range: { C: ranges[unusedMetric]['C'].max, F: ranges[unusedMetric]['F'].max },
                },
            ]);
        }
    };

    const updateFilter = (index, field, value, unit) => {
        setFilters(prevFilters => {
            const newFilters = [...prevFilters];
            if (field === 'range') {
                let metric = newFilters[index].metric

                if (unit === 'C') {
                    newFilters[index].range.C = value;
                    newFilters[index].range.F = [convertMeasurement(value[0], metric, 'F'), convertMeasurement(value[1], metric, 'F')];
                } else {
                    newFilters[index].range.F = value;
                    newFilters[index].range.C = [convertMeasurement(value[0], metric, 'C'), convertMeasurement(value[1], metric, 'C')];
                }

            } else {
                newFilters[index][field] = value;
            }

            if (['type', 'metric'].includes(field)) {

                newFilters[index].range.C = getMinMax(newFilters[index].metric, newFilters[index].type, 'C');
                newFilters[index].range.F = getMinMax(newFilters[index].metric, newFilters[index].type, 'F');
            }

            return newFilters;
        });
    };

    const getMinMax = (metric, type, unit) => {
        return ranges[metric][unit][type].some((value) => value === Number.POSITIVE_INFINITY) ? [0, 100] : ranges[metric][unit][type]
    }

    const removeFilter = (indexToRemove) => {
        setFilters(prevFilters => prevFilters.filter((filter, index) => index !== indexToRemove));
    };

    const handleTypeConfigChange = (filterType, newValues) => {
        setFilterTypeConfig(prevConfig => ({ ...prevConfig, [filterType]: newValues }));
    };


    const handleIsCoastlineChange = (event) => {
        setFilterTypeConfig(prevConfig => ({ ...prevConfig, isCoastline: event.target.checked }));
    };

    const [value, setValue] = useState(0);  // Tab value state

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabInfo = [
        { key: 'coastlines', label: 'Coastlines' },
        { key: 'waterTypes', label: 'Body of Waters' },
        { key: 'waterNames', label: 'Body of Water Names' },
    ];

    const renderFilterSettings = () => {
        return (
            <Paper className={styles.FilterSettings}>
                <FormControlLabel
                    control={<Switch checked={filterTypeConfig.isCoastline} onChange={handleIsCoastlineChange} />}
                    label="Include Coast Beaches"
                />
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={matches ? "standard" : "fullWidth"} // here we choose the variant based on screen size
                    scrollButtons={matches ? "on" : "off"} // here we choose to show or hide scroll buttons based on screen size
                    centered={true} // we only center tabs on non-mobile screens
                    style={{display: 'flex', justifyContent: 'space-evenly'}} // this styles the tabs
                >
                    {tabInfo.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>

                {tabInfo.map((tab, index) => (
                    <TabPanel value={value} index={index}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                            <button
                                variant="contained"
                                className={styles.selectButton}
                                onClick={() => selectAll(tab.key)}
                            >
                                Select All
                            </button>
                            <button
                                variant="contained"
                                className={styles.selectButton}
                                onClick={() => deselectAll(tab.key)}
                            >
                                Deselect All
                            </button>
                        </div>
                        <ToggleButtonGroup
                            value={filterTypeConfig[tab.key]}
                            onChange={(event, newValues) => handleTypeConfigChange(tab.key, newValues)}
                            aria-label="filter type"
                            style={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'center' }}  // Enable wrapping
                        >
                            {allFilterTypes[tab.key].map((item, index) => (
                                <ToggleButton style={{ borderRadius: '0px', borderWidth: '0px', margin: '1px' }} key={index} value={item} aria-label={item}>
                                    {item}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </TabPanel>
                ))}
            </Paper>
        );
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const renderFilter = (filter, index) => {
        return (
            <Grid container className={styles.FilterGridContainer} key={index} direction="column">
                <Grid item container xs={12} justify="space-between" alignItems="center">
                    <Grid item>
                        {/* Your filter title or some element here */}
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12} sm={1}>
                        <IconButton onClick={() => removeFilter(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            select
                            fullWidth
                            value={filter.metric}
                            onChange={(e) => updateFilter(index, 'metric', e.target.value, system)}
                            variant="outlined"
                            className={styles.TextFieldSelect}
                        >
                            {
                                Object.entries(weatherMetrics).map(([key, value]) => {
                                    if (key !== 'winddirection_10m') {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>
                                        );
                                    }
                                    return null;
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            select
                            fullWidth
                            value={filter.type}
                            onChange={(e) => updateFilter(index, 'type', e.target.value, system)}
                            variant="outlined"
                            className={styles.TextFieldSelect}
                        >
                            {['avg', 'min', 'max'].map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ width: "100%" }}>
                    <div className={styles.SliderInputContainer}>
                        <TextField
                            label="Min"
                            type="number"
                            value={filter.range[system][0]}
                            onChange={(e) => updateFilter(index, 'range', [Number(e.target.value), filter.range[system][1]], system)}
                            variant="outlined"
                            size="small"
                            className={styles.TextFieldInput}
                        />
                        <Slider
                            value={filter.range[system]}
                            min={getMinMax(filter.metric, filter.type, system)[0]}
                            max={getMinMax(filter.metric, filter.type, system)[1]}
                            onChange={(e, newValue) => updateFilter(index, 'range', newValue, system)}
                            className={styles.Slider}
                        />
                        <TextField
                            label="Max"
                            type="number"
                            value={filter.range[system][1]}
                            onChange={(e) => updateFilter(index, 'range', [filter.range[system][0], Number(e.target.value)], system)}
                            variant="outlined"
                            size="small"
                            className={styles.TextFieldInput}
                        />
                    </div>
                    {
                        filter.range[system][0] !== Number.POSITIVE_INFINITY &&
                        <div>
                            {`The ${filter.type === 'max' ? 'maximum' : filter.type === 'min' ? 'minimum' : 'average'} 
    ${weatherMetrics[filter.metric].toLowerCase()} has to be between 
    ${formatNumber(filter.range[system][0])} and ${formatNumber(filter.range[system][1])}${units[system][filter.metric]}`}
                        </div>
                    }

                </Grid>
            </Grid>
        );
    };

    return (
        <Box className={styles.FilterBox}>
            {renderFilterSettings()}
            {filters.map((filter, index) => renderFilter(filter, index))}
            <Button variant="contained" onClick={addFilter} style={{ marginTop: '1rem' }} className={styles.FilterButton}>
                +
            </Button>
        </Box>
    );
};

const BeachListSettings = ({ config, setConfig, beaches, styles, sortConfig, setSortConfig, filterConfig, setFilterConfig, ranges, allFilterTypes, filterTypeConfig, setFilterTypeConfig }) => {
    const [showMetrics, setShowMetrics] = useState(false);
    const [showSort, setShowSort] = useState(true);
    const [showFilter, setShowFilter] = useState(false);


    const updateConfig = (name, newValues) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            [name]: {
                ...prevConfig[name],
                ...newValues,
            },
        }));
    };

    const handleSortChange = (event) => {
        const { name, value } = event.target;
        setSortConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
    };

    const handleMetricsToggle = () => setShowMetrics(!showMetrics);
    const handleSortToggle = () => setShowSort(!showSort);
    const handleFilterToggle = () => setShowFilter(!showFilter);


    return (
        <>
            <div className={styles.sectionHeader}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            onClick={handleSortToggle}
                            style={{
                                backgroundColor: showSort ? 'rgba(255, 255, 255, 0.7)' : 'transparent',
                            }}
                        >
                            Sort
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            onClick={handleFilterToggle}
                            style={{
                                backgroundColor: showFilter ? 'rgba(255, 255, 255, 0.7)' : 'transparent',
                            }}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            onClick={handleMetricsToggle}
                            style={{
                                backgroundColor: showMetrics ? 'rgba(255, 255, 255, 0.7)' : 'transparent',
                            }}
                        >
                            Metrics
                        </Button>
                    </Grid>
                </Grid>
            </div>

            {
                showSort && (
                    <div className={styles.settingsSection}>
                        <div style={{ opacity: '0.5' }}>SORT</div>
                        <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], my: 2, mx: 1 }}>
                            <FormControl fullWidth variant="outlined" sx={{ mb: 2, marginRight: 1 }}>
                                <InputLabel id="type-label">Metric</InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="type"
                                    name="type"
                                    value={sortConfig.type}
                                    onChange={handleSortChange}
                                    label="Type"
                                >
                                    {Object.entries(weatherMetrics).map(([value, name]) => {
                                        if (value !== "winddirection_10m") {
                                            return <MenuItem value={value} key={value}>{name}</MenuItem>;
                                        }
                                        return null;
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" sx={{ mb: 2, marginRight: 1 }}>
                                <InputLabel id="metric-label">Type</InputLabel>
                                <Select
                                    labelId="metric-label"
                                    id="metric"
                                    name="metric"
                                    value={sortConfig.metric}
                                    onChange={handleSortChange}
                                    label="Metric"
                                >
                                    <MenuItem value="min">Min</MenuItem>
                                    <MenuItem value="max">Max</MenuItem>
                                    <MenuItem value="avg">Average</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="order-label">Order</InputLabel>
                                <Select
                                    labelId="order-label"
                                    id="order"
                                    name="order"
                                    value={sortConfig.order}
                                    onChange={handleSortChange}
                                    label="Order"
                                >
                                    <MenuItem value="asc">Ascending</MenuItem>
                                    <MenuItem value="desc">Descending</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                )
            }
            {
                showFilter && (
                    <div className={styles.settingsSection}>
                        <div style={{ opacity: '0.5' }}>FILTER</div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', my: 2, mx: 1 }}>
                            {
                                <FilterComponent
                                    ranges={ranges}
                                    filters={filterConfig}
                                    setFilters={setFilterConfig}
                                    styles={styles}
                                    allFilterTypes={allFilterTypes}
                                    filterTypeConfig={filterTypeConfig}
                                    setFilterTypeConfig={setFilterTypeConfig}
                                />
                            }
                        </Box>
                    </div>
                )
            }
            {
                showMetrics && (
                    <div className={styles.settingsSection}>
                        <div style={{ opacity: '0.5' }}>METRICS</div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start', overflowX: 'auto' }}>
                            {Object.keys(config).map((key) => (
                                <ConfigButton key={key} name={key} config={config[key]} updateConfig={updateConfig} />
                            ))}
                        </Box>
                    </div>
                )
            }

        </>
    );
};



export default BeachListSettings;
