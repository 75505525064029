import { useContext } from 'react';
import { DialogContext } from './DialogContext';

function useConfirm() {
    const { openDialog } = useContext(DialogContext);

    return (message) => openDialog(message);
}

export {useConfirm}
