import React, { useState, useEffect, useContext } from 'react';
import { Card, CardContent, Typography, IconButton, Tooltip, Box, Button } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import WaterIcon from '@mui/icons-material/Water';
import CoastIcon from '@mui/icons-material/BeachAccess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getHourlyWeather, getCloudDescription, getPrecipitationDescription, getWindDescription, calculateAverage, getMax, getMin, weatherMetrics, units, measurement, formatNumber, convertMeasurement } from '../utils/utils';
import TemperatureChart from './TemperatureChart';
import DataTable from './DataTable';
import BeachListSettings from './BeachListSettings';
import { UserPreferenceContext } from '../UserPreferenceContext';

const avMinMax = (data) => {
    return [
        parseFloat(calculateAverage(data).toFixed(1)),
        parseFloat(getMax(data).toFixed(1)),
        parseFloat(getMin(data).toFixed(1))
    ];
};

const BeachList = ({ styles, beaches, generateGoogleMapsLink, forecastDay }) => {

    const [expandedCards, setExpandedCards] = useState({});
    const [expandedMetrics, setExpandedMetrics] = useState({});

    const { system } = useContext(UserPreferenceContext);

    const [config, setConfig] = useState({
        temp: { name: 'Temperature', av: false, min: false, max: true },
        precipitation: { name: 'Precipitation', av: true, min: false, max: false },
        cloud: { name: 'Cloud Cover', av: true, min: false, max: false },
        wind: { name: 'Wind Speed', av: true, min: false, max: false },
        gust: { name: 'Wind Gust', av: false, min: false, max: false },
        "solar radiation": { name: 'Solar Radiation', av: false, min: false, max: false },
    });

    const [sortConfig, setSortConfig] = useState({ metric: 'max', type: 'temperature_2m', order: 'desc' });

    const [filterConfig, setFilterConfig] = useState([{
        metric: 'temperature_2m',
        type: 'max',
        range: { C: [0, 100], F: [0, 100] }
    },]);

    const [allFilterTypes, setAllFilterTypes] = useState({
        coastlines: [],
        waterTypes: [],
        waterNames: [],
    });

    const [filterTypeConfig, setFilterTypeConfig] = useState({
        isCoastline: true,
        coastlines: [],
        waterTypes: [],
        waterNames: [],
    });

    const [ranges, setRanges] = useState({});
    const [resolvedBeaches, setResolvedBeaches] = useState([]);

    useEffect(() => {
        const resolveBeachWeathers = async () => {
            const beachesWithResolvedWeathers = await Promise.all(beaches.map(async (beach) => {
                const weather = await beach.weather;
                return { ...beach, weather };
            }));
            setResolvedBeaches(beachesWithResolvedWeathers);
        };

        resolveBeachWeathers();
    }, [beaches]);

    useEffect(() => {
        let rangesInit = {};

        for (let metric in weatherMetrics) {
            let globalMaxValues = {
                min: Number.NEGATIVE_INFINITY,
                max: Number.NEGATIVE_INFINITY,
                avg: Number.NEGATIVE_INFINITY,
            };
            let globalMinValues = {
                min: Number.POSITIVE_INFINITY,
                max: Number.POSITIVE_INFINITY,
                avg: Number.POSITIVE_INFINITY,
            };

            for (let beach of resolvedBeaches) {
                let hourlyWeatherData = getHourlyWeather(forecastDay, beach.weather, metric);
                let min = getMin(hourlyWeatherData);
                let max = getMax(hourlyWeatherData);
                let avg = calculateAverage(hourlyWeatherData);

                // Update global minimum and maximum values if necessary.
                if (min < globalMinValues.min) globalMinValues.min = min;
                if (max < globalMinValues.max) globalMinValues.max = max;
                if (avg < globalMinValues.avg) globalMinValues.avg = avg;

                if (min > globalMaxValues.min) globalMaxValues.min = min;
                if (max > globalMaxValues.max) globalMaxValues.max = max;
                if (avg > globalMaxValues.avg) globalMaxValues.avg = avg;
            }

            let min = [Math.floor(globalMinValues.min), Math.ceil(globalMaxValues.min)]
            let max = [Math.floor(globalMinValues.max), Math.ceil(globalMaxValues.max)]
            let avg = [Math.floor(globalMinValues.avg), Math.ceil(globalMaxValues.avg)]


            rangesInit[metric] = {
                C: {
                    min,
                    max,
                    avg,
                },
                F: {
                    min: [convertMeasurement(min[0], metric, 'F'), convertMeasurement(min[1], metric, 'F')],
                    max: [convertMeasurement(max[0], metric, 'F'), convertMeasurement(max[1], metric, 'F')],
                    avg: [convertMeasurement(avg[0], metric, 'F'), convertMeasurement(avg[1], metric, 'F')],
                }
            };
        }

        setRanges(rangesInit);
        setFilterConfig([{
            metric: 'temperature_2m',
            type: 'max',
            range: {
                C: [rangesInit['temperature_2m']['C'].max[0], rangesInit['temperature_2m']['C'].max[1]],
                F: [rangesInit['temperature_2m']['F'].max[0], rangesInit['temperature_2m']['F'].max[1]],
            }
        },]);

        let coastlines = new Set(); //coastline.name
        let waterTypes = new Set(); //waterBody.type
        let waterNames = new Set(); //waterBody.name

        for (let beach of resolvedBeaches) {
            if (beach.coastline && beach.coastline.name) {
                coastlines.add(beach.coastline.name);
            }
            if (beach.waterBody) {
                if (beach.waterBody.type) {
                    waterTypes.add(beach.waterBody.type);
                }
                if (beach.waterBody.name) {
                    waterNames.add(beach.waterBody.name);
                }
            }
        }

        // Convert back to arrays
        coastlines = Array.from(coastlines);
        waterTypes = Array.from(waterTypes);
        waterNames = Array.from(waterNames);

        setAllFilterTypes({
            coastlines,
            waterTypes,
            waterNames,
        });

        setFilterTypeConfig({
            isCoastline: true,
            coastlines,
            waterTypes,
            waterNames,
        });

    }, [resolvedBeaches, forecastDay]);

    const sortBeaches = (beaches, sortConfig) => {
        const sortedBeaches = [...beaches];

        sortedBeaches.sort((a, b) => {
            const hourlyMetricsA = getHourlyWeather(forecastDay, a.weather, sortConfig.type);
            const hourlyMetricsB = getHourlyWeather(forecastDay, b.weather, sortConfig.type);

            const metricA = sortConfig.metric === 'max' ? getMax(hourlyMetricsA) : sortConfig.metric === 'min' ? getMin(hourlyMetricsA) : calculateAverage(hourlyMetricsA);
            const metricB = sortConfig.metric === 'max' ? getMax(hourlyMetricsB) : sortConfig.metric === 'min' ? getMin(hourlyMetricsB) : calculateAverage(hourlyMetricsB);

            return sortConfig.order === 'asc' ? metricA - metricB : metricB - metricA;
        });
        return sortedBeaches;
    }

    const sortedBeaches = sortBeaches(beaches, sortConfig);

    const filterBeaches = (beaches) => {
        return beaches.filter(beach => {
            // Go through all filterConfig items
            const rangeFiltering = filterConfig.every(filter => {
                const hourlyMetric = getHourlyWeather(forecastDay, beach.weather, filter.metric);
                const metric = filter.type === 'max' ? getMax(hourlyMetric) : filter.type === 'min' ? getMin(hourlyMetric) : calculateAverage(hourlyMetric);
                // is the value within the range of filter.range[0] and filter.range[1]
                return metric >= filter.range.C[0] && metric <= filter.range.C[1];
            });

            // Filter by filterTypeConfig
            const typeFiltering = (() => {
                // Check for coast beach
                if (!filterTypeConfig.isCoastline && beach.isOnCoast) {
                    return false;
                }

                // Check for coastline names
                if (beach.coastline && beach.coastline.name) {
                    if (!filterTypeConfig.coastlines.includes(beach.coastline.name)) {
                        return false;
                    }
                }

                // Check for water body types
                if (beach.waterBody && beach.waterBody.type) {
                    if (!filterTypeConfig.waterTypes.includes(beach.waterBody.type)) {
                        return false;
                    }
                }

                // Check for water body names
                if (beach.waterBody && beach.waterBody.name) {
                    if (!filterTypeConfig.waterNames.includes(beach.waterBody.name)) {
                        return false;
                    }
                }

                return true;
            })();

            return rangeFiltering && typeFiltering;
        });
    };



    const filteredBeaches = filterBeaches(sortedBeaches);

    // useEffect to refresh sort when sortConfig changes
    useEffect(() => {
        sortBeaches(beaches, sortConfig);
    }, [sortConfig, beaches, forecastDay]);

    useEffect(() => {
        filterBeaches(sortedBeaches, filterConfig);
    }, [filterConfig, sortedBeaches]);

    return (
        <div>

            <BeachListSettings
                styles={styles}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
                config={config}
                setConfig={setConfig}
                filterConfig={filterConfig}
                setFilterConfig={setFilterConfig}
                beaches={beaches}
                ranges={ranges}
                allFilterTypes={allFilterTypes}
                filterTypeConfig={filterTypeConfig}
                setFilterTypeConfig={setFilterTypeConfig}
            />

            <ul className={styles.beachesList}>
                {filteredBeaches.length > 0 ? (
                    filteredBeaches.map((beach, index) => {

                        const hourlyTemps = system === 'F'
                            ? getHourlyWeather(forecastDay, beach.weather, 'temperature_2m').map(temp => convertMeasurement(temp, 'temperature_2m', system))
                            : getHourlyWeather(forecastDay, beach.weather, 'temperature_2m');

                        const hourlyPrec = getHourlyWeather(forecastDay, beach.weather, 'precipitation_probability');

                        const hourlyCloud = getHourlyWeather(forecastDay, beach.weather, 'cloudcover');

                        const hourlyWind = system === 'F'
                            ? getHourlyWeather(forecastDay, beach.weather, 'windspeed_10m').map(speed => convertMeasurement(speed, 'windspeed_10m', system))
                            : getHourlyWeather(forecastDay, beach.weather, 'windspeed_10m');

                        const hourlyWindDir = getHourlyWeather(forecastDay, beach.weather, 'winddirection_10m');

                        const hourlyGust = system === 'F'
                            ? getHourlyWeather(forecastDay, beach.weather, 'windgusts_10m').map(speed => convertMeasurement(speed, 'windgusts_10m', system))
                            : getHourlyWeather(forecastDay, beach.weather, 'windgusts_10m');

                        const hourlyRad = getHourlyWeather(forecastDay, beach.weather, 'shortwave_radiation');

                        const [temp, maxTemp, minTemp] = avMinMax(hourlyTemps)
                        const [prec, maxPrec, minPrec] = avMinMax(hourlyPrec)
                        const [cloud, maxCloud, minCloud] = avMinMax(hourlyCloud)
                        const [wind, maxWind, minWind] = avMinMax(hourlyWind)
                        const [gust, maxGust, minGust] = avMinMax(hourlyGust)
                        const [rad, maxRad, minRad] = avMinMax(hourlyRad)

                        const cloudDescription = getCloudDescription(cloud);
                        const precipitationDescription = getPrecipitationDescription(prec);
                        const windDescription = getWindDescription(wind);

                        const area = `Area: ${beach.area === 0 ? "NaN" : Number(Math.round(system === 'F' ? measurement[system]['length'](beach.area) : beach.area)).toLocaleString('en-US', { useGrouping: true, groupSize: 3 }).replace(/,/g, ' ') + units[system].length}`;

                        const metrics = {
                            temp: {
                                name: weatherMetrics['temperature_2m'],
                                av: temp,
                                min: minTemp,
                                max: maxTemp,
                                unit: units[system]['temperature_2m']
                            },
                            precipitation: {
                                name: weatherMetrics['precipitation_probability'],
                                av: prec,
                                min: minPrec,
                                max: maxPrec,
                                unit: units[system]['precipitation_probability']
                            },
                            cloud: {
                                name: weatherMetrics['cloudcover'],
                                av: cloud,
                                min: minCloud,
                                max: maxCloud,
                                unit: units[system]['cloudcover']
                            },
                            wind: {
                                name: weatherMetrics['windspeed_10m'],
                                av: wind,
                                min: minWind,
                                max: maxWind,
                                unit: units[system]['windspeed_10m']
                            },
                            gust: {
                                name: weatherMetrics['windgusts_10m'],
                                av: gust,
                                min: minGust,
                                max: maxGust,
                                unit: units[system]['windgusts_10m']
                            },
                            "solar radiation": {
                                name: weatherMetrics['shortwave_radiation'],
                                av: rad,
                                min: minRad,
                                max: maxRad,
                                unit: units[system]['shortwave_radiation']
                            },
                        };

                        const data = [area];
                        const allData = [area];

                        Object.keys(config).forEach(key => {
                            if (config[key].av) data.push(`${metrics[key].name}: ${metrics[key].av}${metrics[key].unit}`);
                            if (config[key].min) data.push(`Min ${metrics[key].name}: ${metrics[key].min}${metrics[key].unit}`);
                            if (config[key].max) data.push(`Max ${metrics[key].name}: ${metrics[key].max}${metrics[key].unit}`);
                        });

                        Object.keys(config).forEach(key => {
                            allData.push(`${metrics[key].name}: ${metrics[key].av}${metrics[key].unit}`);
                            allData.push(`Min ${metrics[key].name}: ${metrics[key].min}${metrics[key].unit}`);
                            allData.push(`Max ${metrics[key].name}: ${metrics[key].max}${metrics[key].unit}`);
                        });

                        const isExpanded = expandedCards[beach.id] || false;
                        const isExpandedMetric = expandedMetrics[beach.id] || false;

                        return (
                            <li key={index} className={`${styles.beachItem} ${index === 0 ? styles.warmestBeach : ''}`} style={{ '--i': index }}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6" style={{ color: '#FFA500', textShadow: '0px 0px 1px rgba(0, 0, 0, 0.2)' }}>
                                            {beach.tags.name || "Unnamed Beach"}
                                        </Typography>

                                        {isExpanded ? (
                                            <>
                                                <Typography variant="subtitle2" color="textSecondary">{beach.from}</Typography>
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px' }}>
                                                    {beach.isOnCoast && (
                                                        <>
                                                            <Tooltip title="Coastal Beach">
                                                                <CoastIcon style={{ color: '#1e88e5', marginRight: '8px' }} />
                                                            </Tooltip>
                                                            <Typography variant="subtitle2">Coast{(beach.coastline && beach.coastline.name) ? ": " + beach.coastline.name : ''}</Typography>
                                                        </>
                                                    )}
                                                </Box>

                                                {beach.waterBody && (
                                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px' }}>
                                                        <WaterIcon style={{ color: '#4caf50', marginRight: '8px' }} />
                                                        <Typography variant="subtitle2">
                                                            {beach.waterBody.type.charAt(0).toUpperCase() + beach.waterBody.type.slice(1)}
                                                            {beach.waterBody.name ? `: ${beach.waterBody.name}` : ''}
                                                        </Typography>
                                                    </Box>
                                                )}

                                                <br></br>

                                                {
                                                    isExpandedMetric
                                                        ?
                                                        <DataTable list={allData} />
                                                        :
                                                        <DataTable list={data} />
                                                }

                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '5px auto 0px auto', width: '20%', padding: '0px' }}>
                                                    <IconButton onClick={() => setExpandedMetrics({ ...expandedMetrics, [beach.id]: !isExpandedMetric })} style={{ padding: '0px', margin: '-8px', opacity: '80%' }}>
                                                        {isExpandedMetric ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                </div>

                                                <br></br>

                                                <Box style={{ marginTop: '8px' }}>
                                                    <TemperatureChart
                                                        hourlyTemps={hourlyTemps}
                                                        hourlyPrec={hourlyPrec}
                                                        hourlyCloud={hourlyCloud}
                                                        hourlyWind={hourlyWind}
                                                        hourlyWindDir={hourlyWindDir}
                                                        hourlyGust={hourlyGust}
                                                        hourlyRad={hourlyRad} />
                                                </Box>

                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '16px' }}>
                                                    <Typography variant="subtitle1">Directions</Typography>
                                                    <IconButton href={generateGoogleMapsLink(beach)} target="_blank" rel="noopener noreferrer">
                                                        <MapIcon />
                                                    </IconButton>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '8px', flexWrap: 'wrap' }}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    {formatNumber(getMax(hourlyTemps)) + " / " + formatNumber(getMin(hourlyTemps))}
                                                </Typography>
                                                {cloudDescription && (
                                                    <>
                                                        <Typography variant="subtitle2" color="textSecondary" style={{ margin: '0 8px' }}>
                                                            {"|"}
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {cloudDescription}
                                                        </Typography>
                                                    </>
                                                )}
                                                {windDescription && (
                                                    <>
                                                        <Typography variant="subtitle2" color="textSecondary" style={{ margin: '0 8px' }}>
                                                            {"|"}
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {windDescription}
                                                        </Typography>
                                                    </>
                                                )}
                                                {precipitationDescription && (
                                                    <>
                                                        <Typography variant="subtitle2" color="textSecondary" style={{ margin: '0 8px' }}>
                                                            {"|"}
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {precipitationDescription}
                                                        </Typography>
                                                    </>
                                                )}
                                                <IconButton href={generateGoogleMapsLink(beach)} target="_blank" rel="noopener noreferrer">
                                                    <Typography variant="subtitle2" color="textSecondary" style={{ margin: '0 8px', opacity: '60%', fontSize: "0.2rem" }}>
                                                        <MapIcon />
                                                    </Typography>
                                                </IconButton>
                                            </Box>
                                        )}

                                        <Button onClick={() => setExpandedCards({ ...expandedCards, [beach.id]: !isExpanded })}>
                                            {isExpanded ? 'Collapse' : 'Expand'}
                                        </Button>

                                    </CardContent>
                                </Card>
                            </li>
                        );

                    })
                ) : (
                    <li>No beaches found yet.</li>
                )}
            </ul>
        </div>
    );
};

export default BeachList;
