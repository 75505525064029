import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { addDays } from 'date-fns';

const ForecastDatePicker = ({ styles, selectedDate, handleDateChange, setForecastDay, today }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={styles.sectionHeader} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h5">
                        Forecast Date
                    </Typography>
                </div>
                <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
                    <DatePicker
                        value={selectedDate}
                        onChange={(date) => {
                            handleDateChange(date);
                            setForecastDay(date)
                        }}
                        renderInput={(params) =>
                            <TextField {...params} InputProps={{ style: { padding: '0px' } }} />}
                        minDate={today}
                        maxDate={addDays(today, 6)}
                        disableFuture={false}
                    />
                </div>
            </div>
        </LocalizationProvider>
    );
};

export default ForecastDatePicker;