import * as jsts from 'jsts';

function createPolygonsWithHoles(shapes) {
    const factory = new jsts.geom.GeometryFactory();

    let currentId = 0;
    const polygonsWithMetadata = [];

    // Convert shapes to polygons
    for (let shape of shapes) {
        // Convert the shape to a JSTS LinearRing
        const coordinates = shape.map(point => new jsts.geom.Coordinate(point[0], point[1]));
        const linearRing = factory.createLinearRing(coordinates);

        // Assign ID and initialize type and parentId as null
        const polygonMetadata = {
            id: currentId++,
            type: null, // To be determined later
            parentId: null,
            polygon: factory.createPolygon(linearRing)
        };

        polygonsWithMetadata.push(polygonMetadata);
    }

    // Determine type (outer or inner) based on containment depth
    for (let polygonA of polygonsWithMetadata) {
        let containmentDepth = 0;
        for (let polygonB of polygonsWithMetadata) {
            if (polygonA.id !== polygonB.id && polygonB.polygon.contains(polygonA.polygon)) {
                containmentDepth++;
            }
        }
        polygonA.type = containmentDepth % 2 === 0 ? 'outer' : 'inner';
    }

    // Assign parentIds to inner polygons
    for (let inner of polygonsWithMetadata.filter(meta => meta.type === 'inner')) {
        let smallestContainingPolygon = null;
        for (let possibleParent of polygonsWithMetadata.filter(meta => meta.type === 'outer')) {
            if (possibleParent.polygon.contains(inner.polygon)) {
                if (!smallestContainingPolygon || possibleParent.polygon.getArea() < smallestContainingPolygon.polygon.getArea()) {
                    smallestContainingPolygon = possibleParent;
                }
            }
        }
        if (smallestContainingPolygon) {
            inner.parentId = smallestContainingPolygon.id;
        }
    }

    // Collect inner polygons as holes for their parent polygons
    const polygonsWithHoles = [];
    for (let outer of polygonsWithMetadata.filter(meta => meta.type === 'outer')) {
        const innerRings = polygonsWithMetadata.filter(meta => meta.type === 'inner' && meta.parentId === outer.id)
            .map(inner => inner.polygon.getExteriorRing());

        // Creating a new polygon with the outer ring and inner rings (holes)
        const newPolygonWithHoles = factory.createPolygon(outer.polygon.getExteriorRing(), innerRings);

        // Store the new polygon with holes
        polygonsWithHoles.push(newPolygonWithHoles)
    }

    return polygonsWithHoles;
}



function findContours(originalCanvas) {

    const canvas = originalCanvas[0].map((_, colIndex) => originalCanvas.map(row => row[colIndex])).map(row => row.reverse());

    const directions = [
        [-1, 0], [-1, 1], [0, 1], [1, 1],
        [1, 0], [1, -1], [0, -1], [-1, -1]
    ];
    let contours = [];
    let visited = Array.from({ length: canvas.length }, () => Array(canvas[0].length).fill(false));
    let dilVisited = Array.from({ length: canvas.length }, () => Array(canvas[0].length).fill(false));

    function isValid(x, y) {
        return x >= 0 && y >= 0 && x < canvas.length && y < canvas[0].length && !visited[x][y];
    }

    function isBorder(x, y) {
        for (const [dx, dy] of directions) {
            let newX = x + dx;
            let newY = y + dy;
            if (newX < 0 || newY < 0 || newX >= canvas.length || newY >= canvas[0].length || canvas[newX][newY] === 0) {
                return true;
            }
        }
        return false;
    }

    function dilation(visited) {

        // Create a new array to store the result
        let result = Array.from({ length: visited.length }, () => Array(visited[0].length).fill(false));

        // Copy the original array to the result array
        for (let i = 0; i < visited.length; i++) {
            for (let j = 0; j < visited[i].length; j++) {
                result[i][j] = visited[i][j];
            }
        }

        // Iterate through each element in the array
        for (let i = 0; i < visited.length; i++) {
            for (let j = 0; j < visited[i].length; j++) {
                // If the current element is true
                if (visited[i][j] === true) {
                    // Iterate through the 8 directions
                    for (let dir of directions) {
                        let ni = i + dir[0];
                        let nj = j + dir[1];

                        // Check if the neighbor coordinates are within the bounds of the array
                        if (ni >= 0 && ni < visited.length && nj >= 0 && nj < visited[i].length) {
                            // Set the neighbor to true in the result array
                            result[ni][nj] = true;
                        }
                    }
                }
            }
        }

        // Return the dilated array
        return result;
    }

    for (let i = 0; i < canvas.length; i++) {
        for (let j = 0; j < canvas[0].length; j++) {

            let curVisited = Array.from({ length: canvas.length }, () => Array(canvas[0].length).fill(false));

            if (canvas[i][j] === 1 && !dilVisited[i][j] && !curVisited[i][j] && isBorder(i, j)) {
                let contour = [];
                let x = i, y = j;
                let dir = 0;
                let loopCompleted = false; // Flag to track if the loop completed naturally

                do {
                    contour.push([x, y]);
                    curVisited[x][y] = true;

                    let b = (dir + 6) % 8;
                    let foundNext = false;

                    for (let d = 0; d < 8; d++) {
                        let newDir = (b + d) % 8;
                        let newX = x + directions[newDir][0];
                        let newY = y + directions[newDir][1];

                        if (isValid(newX, newY) && canvas[newX][newY] === 1 && isBorder(newX, newY)) {
                            x = newX;
                            y = newY;
                            dir = newDir;
                            foundNext = true;
                            break;
                        }
                    }

                    if (!foundNext) break;

                    if (x === i && y === j) {
                        loopCompleted = true;
                    }

                } while (x !== i || y !== j);

                if (loopCompleted) {
                    // Push the starting point again so that it is also the ending point
                    contour.push([i, j]);

                    // Merge curVisited into visited
                    for (let i = 0; i < canvas.length; i++) {
                        for (let j = 0; j < canvas[0].length; j++) {
                            visited[i][j] = visited[i][j] || curVisited[i][j];
                        }
                    }

                    // Perform dilation
                    dilVisited = dilation(visited);

                    // Add contour to contours
                    contours.push(contour);
                }
            }

        }
    }

    return contours;
}

function mapCoordinates(pixelCoords, originalBounds, newBounds) {
    const [originalYMax, originalXMin] = originalBounds[0];
    const [originalYMin, originalXMax] = originalBounds[1];
    const [newYMax, newXMin] = newBounds[0];
    const [newYMin, newXMax] = newBounds[1];

    const scaleX = (newXMax - newXMin) / (originalXMax - originalXMin);
    const scaleY = (newYMin - newYMax) / (originalYMax - originalYMin);

    return pixelCoords.map(segment => {
        return segment.map(([x, y]) => {
            const newX = newXMin + (x - originalXMin) * scaleX;
            const newY = newYMax + (originalYMax - y) * scaleY;
            return [newX, newY];
        });
    });
}



export { findContours, mapCoordinates, createPolygonsWithHoles }
