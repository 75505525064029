import React, { useState, useContext, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Compass from './compass';
import { UserPreferenceContext } from '../UserPreferenceContext';

const styles = {
    dropdown: {
        fontSize: '13px',
        padding: '8px 10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        backgroundColor: '#fff',
        color: '#444',
    }
}

const CustomizedDot = ({ cx, cy, value, windDir }) => {
    if (windDir === undefined) {
        return (
            <circle cx={cx} cy={cy} r={3} fill="#8884d8" />
        );
    } else {
        return (
            <Compass direction={windDir} cx={cx} cy={cy} />
        );
    }
};

const WeatherChart = ({ hourlyTemps, hourlyPrec, hourlyCloud, hourlyWind, hourlyWindDir, hourlyGust, hourlyRad }) => {
    const [currentChart, setCurrentChart] = useState("Temperature");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { system } = useContext(UserPreferenceContext);

    const switchData = (chart) => {
        let data;
        let unitLabel;

        switch (chart) {
            case "Temperature":
                unitLabel = `(${system})`;
                data = hourlyTemps.map((temp, index) => ({
                    name: `${index}:00`,
                    value: system === 'F' ? Math.round((temp * 9 / 5) + 32) : temp
                }));
                break;
            case "Precipitation":
                unitLabel = '(%)';
                data = hourlyPrec.map((prec, index) => ({
                    name: `${index}:00`,
                    value: prec
                }));
                break;
            case "Cloud Cover":
                unitLabel = '(%)';
                data = hourlyCloud.map((cloud, index) => ({
                    name: `${index}:00`,
                    value: cloud
                }));
                break;
            case "Wind Speed":
                unitLabel = '(km/h)';
                data = hourlyWind.map((wind, index) => ({
                    name: `${index}:00`,
                    value: wind,
                    windDir: hourlyWindDir[index]
                }));
                break;
            case "Wind Gust":
                unitLabel = '(km/h)';
                data = hourlyGust.map((gust, index) => ({
                    name: `${index}:00`,
                    value: gust
                }));
                break;
            case "Radiation":
                unitLabel = '(W/m2)';
                data = hourlyRad.map((rad, index) => ({
                    name: `${index}:00`,
                    value: rad
                }));
                break;
        }

        return { data, unitLabel };
    };

    const calculateMargin = (min, max) => {
        const range = max - min;
        return range === 0 ? 2 : range * 0.05; // 5% of range or 2 if range is 0
    }

    const isPercentage = currentChart === 'Precipitation' || currentChart === 'Cloud Cover';
    const domain = (minVal, maxVal) => { return isPercentage ? [0, 100] : [minVal - calculateMargin(minVal, maxVal), maxVal + calculateMargin(minVal, maxVal)]; }

    const formatTick = (tick) => {
        if (Number.isInteger(tick)) {
            return tick.toFixed(0);
        } else if (tick < 0.01) {
            return tick.toExponential(2);
        } else {
            const absLog = Math.log10(Math.abs(tick));
            const decimalPlaces = Math.max(0, Math.ceil(2 - absLog));
            return tick.toFixed(decimalPlaces);
        }
    }



    const { data, unitLabel } = switchData(currentChart);
    const minVal = Math.min(...data.map(d => d.value));
    const maxVal = Math.max(...data.map(d => d.value));

    const fontSize = isMobile ? 10 : 20;
    const chartMargin = isMobile ? { top: 20, right: 5, left: 0, bottom: 5 } : { top: 20, right: 30, left: 20, bottom: 5 };
    const labelPosition = isMobile ? 'insideLeft' : 'insideLeft';

    return (
        <div>
            <select
                style={styles.dropdown}
                value={currentChart}
                onChange={(e) => setCurrentChart(e.target.value)}
            >
                <option value="Temperature">Temperature</option>
                <option value="Precipitation">Precipitation Probability</option>
                <option value="Cloud Cover">Cloud Cover</option>
                <option value="Wind Speed">Wind Speed and Direction</option>
                <option value="Wind Gust">Wind Gust</option>
                <option value="Radiation">Radiation</option>
            </select>

            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={data} animationDuration={500} margin={chartMargin}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey="value" label={{ value: unitLabel, angle: -90, position: labelPosition, fontSize: fontSize }} domain={domain(minVal, maxVal)} tickFormatter={formatTick} />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#8884d8" animationDuration={0}
                        dot={props => <CustomizedDot {...props} windDir={props.payload.windDir} />} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default WeatherChart;
