import { generateKeyWeather, getFromCache } from './utils/ApiCache';

let requestsMade = Number(localStorage.getItem('requestsMade')) || 0;

export const incrementRequests = async (beaches, confirm) => {
    const currentDate = new Date().toLocaleDateString();
    const twoHours = 2 * 60 * 60 * 1000;

    // Filter out the beaches already in local storage
    const newBeaches = beaches.filter(beach => {
        const key = generateKeyWeather(beach.center.lat, beach.center.lon, currentDate);
        return !getFromCache(key, twoHours);
    });

    const count = newBeaches.length;

    if (requestsMade + count > 4000) {
        let status = await confirm(`You have now made ${requestsMade + count} of 5000 possible weather requests`);
        if(!status) {
            return false;
        }
    } 

    if (requestsMade + count > 5000) {
        await confirm("You cannot proceed because this request will exceed the daily limit of 5000 weather requests.");
        return false;
    } 

    const newTotal = requestsMade + count;
    requestsMade = newTotal;
    localStorage.setItem('requestsMade', newTotal);

    return true;
};


export const resetDailyRequests = () => {
    const lastTimestamp = Number(localStorage.getItem('timestamp')) || 0;
    const now = new Date();
    const lastDate = new Date(lastTimestamp);
    
    if (lastDate.getDate() !== now.getDate() || 
        lastDate.getMonth() !== now.getMonth() ||
        lastDate.getFullYear() !== now.getFullYear()) {
        requestsMade = 0;
        localStorage.setItem('requestsMade', '0');
    }
    
    localStorage.setItem('timestamp', now.getTime().toString());
    return requestsMade;
};
