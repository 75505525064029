import { useLocation, Link } from "react-router-dom";

function NavLink({ to, children }) {
  let location = useLocation();
  
  return (
    <Link to={to} className={location.pathname === to ? 'active' : ''}>
      {children}
    </Link>
  );
}

export default NavLink;
