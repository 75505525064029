import React from 'react';
import styles from './Dialog.module.css';

function Dialog({ isOpen, message, onConfirm, onCancel }) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.dialog}>
            <p>{message}</p>
            <button onClick={onConfirm} className={styles.okButton}>OK</button>
            <button onClick={onCancel} className={styles.cancelButton}>Cancel</button>
        </div>
    );
}

export default Dialog;
