import * as turf from '@turf/turf';
import RBush from 'rbush';
import { getNearbyWaterBodiesForBatch } from './ApiCache';

const overpassUrl = (query) => `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(query)}`;

function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const date_str = `${year}-${month}-${day}`;
    return date_str;
}

function getHourlyArray(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    const hourlyArray = hours.map(hour => `${year}-${month}-${day}T${hour}:00`);
    return hourlyArray;
}

const calculateAverage = (data) => {
    return data.reduce((a, b) => a + b, 0) / data.length;
}

const getMax = (data) => {
    return data.reduce((a, b) => Math.max(a, b));
}

const getMin = (data) => {
    return data.reduce((a, b) => Math.min(a, b));
}

function getHourlyWeather(day, weather, weatherMetric) {

    // temperature_2m

    const hourlyArray = getHourlyArray(day);

    const indices = hourlyArray.map(hourlyTime => weather.hourly.time.indexOf(hourlyTime));

    let hourlyWeatherData = [];

    if (weather && weather.hourly && weather.hourly[weatherMetric]) {
        hourlyWeatherData = indices.map(index => weather.hourly[weatherMetric][index]);
    }

    return hourlyWeatherData;
}

// Calculate the area of a beach
const calculateBeachArea = (beach) => {
    if (beach.geometry) {
        const points = beach.geometry.map(point => [point.lon, point.lat]);

        // Make sure the polygon is closed by adding the first point to the end if necessary
        if (points[0][0] !== points[points.length - 1][0] || points[0][1] !== points[points.length - 1][1]) {
            points.push(points[0]);
        }

        const polygon = {
            type: "Polygon",
            coordinates: [points],
        };

        return turf.area(polygon);
    }
    return 0;
};

const setCenter = (beach) => {
    let lat, lon;

    if (beach.center) {
        lat = beach.center.lat;
        lon = beach.center.lon;
    } else if (beach.type === "node") {
        lat = beach.lat;
        lon = beach.lon;
    } else {  // way or relation
        const points = beach.geometry.map(point => [point.lon, point.lat]);
        // Make sure the polygon is closed
        if (points[0][0] !== points[points.length - 1][0] || points[0][1] !== points[points.length - 1][1]) {
            points.push(points[0]);
        }
        const polygon = {
            type: "Polygon",
            coordinates: [points],
        };
        const center = turf.centroid(polygon);
        lat = center.geometry.coordinates[1];
        lon = center.geometry.coordinates[0];
    }

    return { lat, lon }
};

// Generate Google Maps link
const generateGoogleMapsLink = (beach) => {
    const { lat, lon } = beach.center
    return `https://maps.google.com/maps?q=${lat},${lon}`;
};


const calculateRadius = (center, geometry) => {
    const coordinates = geometry.getCoordinates();
    let maxDistance = 0;

    const centerPoint = turf.point([center.x, center.y]);

    for (let i = 0; i < coordinates.length; i++) {
        const point = turf.point([coordinates[i].x, coordinates[i].y]);

        // Calculate the distance in meters using Turf.js
        const distance = turf.distance(centerPoint, point, { units: 'meters' });

        if (distance > maxDistance) {
            maxDistance = distance;
        }
    }

    return maxDistance;
};

async function getNearestWaterBody(lat, lon, waterBodies) {
    let nearestWaterBody = null;

    // Create a new R-tree
    const tree = new RBush();

    // Load all the points into the tree
    waterBodies.forEach((body, index) => {
        let points;
        if (body.type === 'way') {
            points = body.geometry;
        } else if (body.type === 'relation') {
            points = body.members.flatMap(member => member.geometry || []);
        }

        points.forEach(point => {
            tree.insert({ minX: point.lon, minY: point.lat, maxX: point.lon, maxY: point.lat, body });
        });
    });

    // Search for the nearest point
    let minDistance = Infinity;
    tree.all().forEach(item => {
        const distance = calculateDistance(lat, lon, item.minY, item.minX);
        if (distance < minDistance) {
            minDistance = distance;
            nearestWaterBody = item.body;
        }
    });

    if (nearestWaterBody) {
        return {
            type: nearestWaterBody.tags.waterway || nearestWaterBody.tags.water || nearestWaterBody.tags.place || (nearestWaterBody.tags.natural ? "Unknown Body of Water" : null),
            name: nearestWaterBody.tags.name || null,
        };
    } else {
        return null;
    }
}


function calculateDistance(lat1, lon1, lat2, lon2) {
    const from = turf.point([lon1, lat1]);
    const to = turf.point([lon2, lat2]);
    const options = { units: 'meters' };

    return turf.distance(from, to, options);
}

async function processBeach(beachBatch) {
    const waterBodies = await getNearbyWaterBodiesForBatch(beachBatch);

    for (let beach of beachBatch) {
        const beachWaterBodies = waterBodies.filter(body => {
            let points;
            if (body.type === 'way') {
                points = body.geometry;
            } else if (body.type === 'relation') {
                points = body.members.flatMap(member => member.geometry);
            }

            // Ensure points is defined and is an array.
            if (!points || !Array.isArray(points)) {
                return false;
            }

            return points.some(point => {
                // Ignore undefined points and points without lat and lon
                if (!point || !('lat' in point && 'lon' in point)) {
                    return false;
                }

                return calculateDistance(beach.center.lat, beach.center.lon, point.lat, point.lon) < 1000; // Replace 1000 with your actual radius
            });
        });

        // Determine if the beach is on a coast and find coastline details
        beach.isOnCoast = beachWaterBodies.some(body => body.type === 'way' && body.tags.natural === 'coastline');
        const coastline = beachWaterBodies.find(body => body.type === 'way' && body.tags.natural === 'coastline');
        if (coastline && beach.isOnCoast) {
            beach.coastline = {
                name: coastline.tags.name || null
            };
        }

        // Filter out coastlines before finding the nearest water body
        const nonCoastlineBodies = beachWaterBodies.filter(body => !(body.type === 'way' && body.tags.natural === 'coastline'));

        const nearestWaterBody = await getNearestWaterBody(beach.center.lat, beach.center.lon, nonCoastlineBodies, beach.isOnCoast);
        beach.waterBody = nearestWaterBody;
    }

    return beachBatch;
}

function getCloudDescription(averageCloud) {
    if (averageCloud <= 10) return "Clear";
    else if (averageCloud <= 50) return "Partly Cloudy";
    else if (averageCloud <= 75) return "Mostly Cloudy";
    else return "Cloudy";
}

function getPrecipitationDescription(averagePrecipitation) {
    if (averagePrecipitation <= 10) return;
    else if (averagePrecipitation <= 30) return "Possibility of a light shower.";
    else if (averagePrecipitation <= 40) return "Chance of rain.";
    else if (averagePrecipitation <= 50) return "Moderate chance of rain.";
    else if (averagePrecipitation <= 60) return "Rain is likely.";
    else if (averagePrecipitation <= 80) return "High chance of rain.";
    else return "Rain.";
}

function getWindDescription(averageWind) {
    if (averageWind <= 1) return "Calm";
    else if (averageWind <= 5) return "Light Air";
    else if (averageWind <= 11) return "Light Breeze";
    else if (averageWind <= 19) return "Gentle Breeze";
    else if (averageWind <= 28) return "Moderate Breeze";
    else if (averageWind <= 38) return "Fresh Breeze";
    else if (averageWind <= 49) return "Strong Breeze";
    else if (averageWind <= 61) return "Near Gale";
    else if (averageWind <= 74) return "Gale";
    else if (averageWind <= 88) return "Severe Gale";
    else if (averageWind <= 102) return "Strong storm";
    else if (averageWind <= 117) return "Violent storm";
    else return "Hurricane";
}

const units = {
    C: {
        temperature_2m: "°C",
        precipitation_probability: "%",
        cloudcover: "%",
        windspeed_10m: " km/h",
        winddirection_10m: "°",
        windgusts_10m: " km/h",
        shortwave_radiation: " W/m²",
        length: ' m²',
    },
    F: {
        temperature_2m: "°F",
        precipitation_probability: "%",
        cloudcover: "%",
        windspeed_10m: " mph",
        winddirection_10m: "°",
        windgusts_10m: " mph",
        shortwave_radiation: " W/m²",
        length: ' ft²',
    }
};

const measurement = {
    F: { // From Metric to Imperial
        temperature: tempC => (tempC * 9 / 5) + 32, // Celsius to Fahrenheit
        speed: speedKmH => speedKmH * 0.621371, // Kilometers per hour to miles per hour
        length: lengthM => lengthM * 3.28084, // Meters to feet
    },
    C: { // From Imperial to Metric
        temperature: tempF => (tempF - 32) * 5 / 9, // Fahrenheit to Celsius
        speed: speedMph => speedMph / 0.621371, // Miles per hour to kilometers per hour
        length: lengthFt => lengthFt / 3.28084, // Feet to meters
    }
};

const measurementToConversion = {
    temperature_2m: "temperature",
    windspeed_10m: "speed",
    windgusts_10m: "speed",
    length: "length"
};

const weatherMetrics = {
    temperature_2m: 'Temperature',
    precipitation_probability: 'Precipitation',
    cloudcover: 'Cloud Cover',
    windspeed_10m: 'Wind Speed',
    winddirection_10m: 'Wind Direction',
    windgusts_10m: 'Wind Gusts',
    shortwave_radiation: 'Solar Radiation',
};

const convertMeasurement = (value, metric, system) => {
    try {
        const conversionType = measurementToConversion[metric];
        let convertedValue = measurement[system][conversionType](value);
        return convertedValue;
    } catch (error) {
        return value;
    }
};

function formatNumber(number) {
    if (Number.isInteger(number)) {
        return number;
    } else {
        return number.toFixed(2);
    }
}

export {
    overpassUrl,
    calculateBeachArea,
    generateGoogleMapsLink,
    calculateRadius,
    calculateDistance,
    setCenter,
    processBeach,
    formatDate,
    getHourlyArray,
    getHourlyWeather,
    getCloudDescription,
    getPrecipitationDescription,
    getWindDescription,
    calculateAverage,
    getMax,
    getMin,
    weatherMetrics,
    units,
    measurement,
    measurementToConversion,
    convertMeasurement,
    formatNumber,
}