import React, { useState, useEffect } from 'react';
import styles from './css/Notification.module.css';
import { Link, useLocation } from 'react-router-dom';

function FirstTimeUserGuide() {
    return (
        <div className={styles.guideContainer}>
            <Link to="/blog/How_to_Use_the_Website" className={styles.guideLink}>
                <div className={styles.guideTextContainer}>
                    <p className={styles.guideTitle}>
                        Click Here for Our Step-by-Step Guide
                    </p>
                    <p className={styles.guideDescription}>
                        As a first-time user, you might be interested in this guide on how to use the site.
                    </p>
                </div>
            </Link>
        </div>
    );
}

function Notification() {
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(localStorage.getItem('hideNotification') === null);

    const hideNotification = () => {
        setIsVisible(false);
        localStorage.setItem('hideNotification', Date.now());
    };

    useEffect(() => {
        if (location.pathname === '/blog/How_to_Use_the_Website') {
            hideNotification();
        }
    }, [location]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.container}>
            <FirstTimeUserGuide />
            <button className={styles.hideButton} onClick={hideNotification}>
                Hide
            </button>
        </div>
    );
}

export { Notification }
