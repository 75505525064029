import React, { useEffect, useContext } from 'react';
import { UserPreferenceContext } from '../UserPreferenceContext';
import './TemperatureUnitToggle.css';

const TemperatureUnitToggle = ({ onToggle }) => {
    const { system, setPreference } = useContext(UserPreferenceContext);

    useEffect(() => {
        if (onToggle) {
            onToggle(system);
        }
    }, [system, onToggle]);

    const handleToggleChange = () => {
        const newSystem = system === 'C' ? 'F' : 'C';
        setPreference(newSystem);
    };

    return (
        <div className="toggle-button-cover">
            <input
                className="checkbox"
                type="checkbox"
                checked={system === 'F'}
                onChange={handleToggleChange}
            />
            <div className="knobs"></div>
            <div className="layer"></div>
            <div className="label-font" 
                style={{ 
                    position: "absolute", 
                    top: "50%", 
                    left: "10px", 
                    transform: "translateY(-50%)",
                    fontWeight: system === 'C' ? 'bold' : 'normal' 
                }}>
                C
            </div>
            <div className="label-font" 
                style={{ 
                    position: "absolute", 
                    top: "50%", 
                    right: "10px", 
                    transform: "translateY(-50%)",
                    fontWeight: system === 'F' ? 'bold' : 'normal' 
                }}>
                F
            </div>
        </div>
    );
};


export default TemperatureUnitToggle;