import React, { createContext, useState } from 'react';
import Dialog from './Dialog';

const DialogContext = createContext();

function DialogProvider({ children }) {
    const [dialog, setDialog] = useState({ isOpen: false, message: '', resolve: null });

    const openDialog = (message) => {
        return new Promise((resolve) => {
            setDialog({ isOpen: true, message, resolve });
        });
    };

    const handleConfirm = () => {
        dialog.resolve(true);
        setDialog({ isOpen: false, message: '', resolve: null });
    };

    const handleCancel = () => {
        dialog.resolve(false);
        setDialog({ isOpen: false, message: '', resolve: null });
    };

    return (
        <DialogContext.Provider value={{ openDialog, handleConfirm, handleCancel }}>
            {children}
            <Dialog
                isOpen={dialog.isOpen}
                message={dialog.message}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </DialogContext.Provider>
    );
}

export { DialogProvider, DialogContext };
