// SearchPlaces.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { useCombobox } from 'downshift';
import styles from './SearchPlaces.module.css';
import useDebounce from './useDebounce';
import { ThemeProvider, createTheme, Tabs, Tab, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import { incrementRequests, resetDailyRequests } from './rateLimiter';
import ReactGA from 'react-ga4';
import * as jsts from 'jsts';
import TemperatureUnitToggle from './components/TemperatureUnitToggle';
import RegionList from './components/RegionList';
import RegionSearch from './components/RegionSearch';
import ForecastDatePicker from './components/ForecastDatePicker';
import BeachList from './components/BeachList';
import MapCanvas from './components/MapCanvas';
import BeachMap from './components/BeachMap';
import { calculateBeachArea, generateGoogleMapsLink, calculateRadius, setCenter } from './utils/utils';
import { fetchWeather, fetchBeaches, fetchAndCacheItems, fetchBoundaries } from './utils/ApiCache';
import { useConfirm } from './components/dialog/DialogHooks';
import { UserPreferenceContext } from './UserPreferenceContext';
import { Helmet } from 'react-helmet';

const theme = createTheme({
    palette: {
        primary: {
            main: '#229091',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

function SearchPlaces() {

    const [inputItems, setInputItems] = useState([]);
    const [inputFocused, setInputFocused] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingBeaches, setLoadingBeaches] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [boundaryStatus, setBoundaryStatus] = useState({});
    const [beaches, setBeaches] = useState([]);
    const [error, setError] = useState(null);
    const today = new Date();
    const [forecastDay, setForecastDay] = useState(today);
    const debouncedInputValue = useDebounce(inputValue, 250);
    const [selectedDate, handleDateChange] = useState(today);
    const [popupContent, setPopupContent] = useState('');
    const [isMapVisible, setIsMapVisible] = useState(false);
    const [listMode, setListMode] = useState(true);

    const { system } = useContext(UserPreferenceContext);

    const confirm = useConfirm();


    useEffect(() => {
        resetDailyRequests();  // Reset counter if it's a new day
    }, []);

    const CancelToken = axios.CancelToken; // This is the class we need from axios
    const cancelFetch = useRef(null);

    useEffect(() => {
        if (debouncedInputValue) {
            fetchItems(debouncedInputValue);
        } else {
            setInputItems([]);
        }

        return () => {
            if (cancelFetch.current) cancelFetch.current();
        };
    }, [debouncedInputValue]);

    const toggleMapVisibility = () => {
        setIsMapVisible(!isMapVisible);
    };

    const fetchItems = async (input) => {
        setLoading(true);
        try {
            const items = await fetchAndCacheItems(input, new CancelToken(function executor(c) {
                cancelFetch.current = c;
            }));

            setInputItems(items);
            setLoading(false);
        } catch (error) {
            if (axios.isCancel(error)) {
                // Handle cancelation here if necessary
            } else {
                setLoading(false);
            }
        }
    };

    const getPolygons = (data) => {
        const reader = new jsts.io.GeoJSONReader();
        const factory = new jsts.geom.GeometryFactory();
        const outerPolygonizer = new jsts.operation.polygonize.Polygonizer();
        const innerPolygonizer = new jsts.operation.polygonize.Polygonizer();

        const outerWays = data.members
            .filter(member => member.type === 'way' && member.role === 'outer')
            .map(way => reader.read({
                type: 'LineString',
                coordinates: way.geometry.map(point => [point.lon, point.lat])
            }));

        const innerWays = data.members
            .filter(member => member.type === 'way' && member.role === 'inner')
            .map(way => reader.read({
                type: 'LineString',
                coordinates: way.geometry.map(point => [point.lon, point.lat])
            }));

        // Add the LineStrings to the outerPolygonizer
        outerWays.forEach(way => outerPolygonizer.add(way));

        // Add the LineStrings to the innerPolygonizer
        innerWays.forEach(way => innerPolygonizer.add(way));

        // Get the resulting Polygons
        const outerPolygonsCollection = outerPolygonizer.getPolygons();
        const innerPolygonsCollection = innerPolygonizer.getPolygons();

        // Convert the Java collections to JavaScript arrays
        const outerPolygons = [];
        const innerPolygons = [];

        for (let iterator = outerPolygonsCollection.iterator(); iterator.hasNext();) {
            outerPolygons.push(iterator.next());
        }

        for (let iterator = innerPolygonsCollection.iterator(); iterator.hasNext();) {
            innerPolygons.push(iterator.next());
        }

        // Collect inner polygons as holes for their parent polygons
        const polygonsWithHoles = [];
        for (let outer of outerPolygons) {
            const innerRings = innerPolygons.filter(inner => outer.contains(inner)).map(inner => inner.getExteriorRing());

            // Creating a new polygon with the outer ring and inner rings (holes)
            const newPolygonWithHoles = factory.createPolygon(outer.getExteriorRing(), innerRings);

            // Store the new polygon with holes along with center and minRadius
            polygonsWithHoles.push(newPolygonWithHoles);
        }

        return polygonsWithHoles;
    };


    const filterBeachesWithinBoundary = (beaches, polygon, innerPolygons) => {
        const reader = new jsts.io.GeoJSONReader();
        const filteredBeaches = [];

        for (let beach of beaches) {
            let geometry;
            if (beach.type === 'node') {
                geometry = reader.read({ type: 'Point', coordinates: [beach.lon, beach.lat] });
                beach.center = { lon: beach.lon, lat: beach.lat }
            } else if (beach.type === 'way' || beach.type === 'relation') {
                const bounds = beach.bounds;
                const corners = [
                    [bounds.minlon, bounds.minlat],
                    [bounds.minlon, bounds.maxlat],
                    [bounds.maxlon, bounds.maxlat],
                    [bounds.maxlon, bounds.minlat],
                    [bounds.minlon, bounds.minlat] // Closed loop
                ];
                geometry = reader.read({ type: 'Polygon', coordinates: [corners] });
                const centroidGeometry = geometry.getCentroid();
                const center = centroidGeometry.getCoordinate();
                beach.center = { lon: center.x, lat: center.y };
            }

            if (innerPolygons.some(inner => inner.geometry.contains(geometry))) continue;

            if (polygon.intersects(geometry)) {
                filteredBeaches.push(beach);
            }
        }

        return filteredBeaches;
    };

    const removeSelectedItem = (item) => {
        setSelectedItems(selectedItems.filter(i => i !== item));
    };

    const searchBeaches = async () => {
        if (selectedItems.length === 0) {
            return;
        }

        setLoadingBeaches(true);

        let allFilteredBeaches = new Map();

        const addBeachesToSet = (beaches, map, name) => {
            for (const beach of beaches) {
                const beachStr = JSON.stringify(beach);
                if (map.has(beachStr)) {
                    continue
                } else {
                    map.set(beachStr, name);
                }
            }
        };


        for (const selectedItem of selectedItems) {
            let regionName;
            let polygons;

            if (selectedItem.inputType === "region") {
                regionName = `${selectedItem.tags["wikipedia"] ? selectedItem.tags["wikipedia"].split(':')[1] : selectedItem.tags["name"]}${selectedItem.extra ? ` (${selectedItem.extra})` : ''}`;
                polygons = getPolygons(selectedItem);
            } else if (selectedItem.inputType === "drawn") {
                regionName = selectedItem.name;
                polygons = selectedItem.geometry;
            }

            if (regionName && polygons) {
                // Calculate the average center point
                let totalX = 0;
                let totalY = 0;
                for (let polygon of polygons) {
                    const centroidGeometry = polygon.getCentroid();
                    const center = centroidGeometry.getCoordinate();
                    totalX += center.x;
                    totalY += center.y;
                }
                const averageCenter = { x: totalX / polygons.length, y: totalY / polygons.length };

                // Calculate the maximum radius
                let maxRadius = 0;
                for (let polygon of polygons) {
                    const centroidGeometry = polygon.getCentroid();
                    const center = centroidGeometry.getCoordinate();
                    const radius = calculateRadius(center, polygon);
                    if (radius > maxRadius) {
                        maxRadius = radius;
                    }
                }

                if (maxRadius > 200000) {
                    setLoadingBeaches(false);
                    await confirm(`'${regionName}' is too big (${maxRadius.toFixed(0)} m radius out of a max radius of 200000 m)`);
                    return;
                } else {
                    // Fetch beaches within the max radius
                    let beaches = await fetchBeaches(averageCenter, maxRadius);
                    if (beaches.error) {
                        setLoadingBeaches(false);
                        setPopupContent(beaches.error);
                        return;
                    }
                    beaches = beaches.filter(beach => beach.tags && beach.tags.name);

                    // Filter beaches by polygons
                    for (let polygon of polygons) {
                        const filteredBeaches = filterBeachesWithinBoundary(beaches, polygon, []);
                        addBeachesToSet(filteredBeaches, allFilteredBeaches, regionName);
                    }
                }

            }
        }

        // Convert Set back to Array after processing
        let id = 0;
        allFilteredBeaches = Array.from(allFilteredBeaches).map(([beachStr, from]) => {
            const beach = JSON.parse(beachStr);
            beach.id = id++;
            beach.from = from;
            return beach;
        });

        if (allFilteredBeaches.length > 500) {
            setLoadingBeaches(false);
            await confirm(`Too many beaches for one search (${allFilteredBeaches.length})`);
            return;
        }

        const canProceed = await incrementRequests(allFilteredBeaches, confirm);

        if (!canProceed) {
            setLoadingBeaches(false);
            return;
        }

        ReactGA.event("API_CALL", {
            category: "User",
            non_interaction: true,
            event: {
                custom_params: {
                    API_call_count: `${allFilteredBeaches.length}` // This line sends custom event parameters.
                },
            },
        });

        for (let beach of allFilteredBeaches) {
            beach.area = calculateBeachArea(beach)
            beach.center = setCenter(beach)
        }

        const BeachWorker = new Worker(new URL('./workers/beachWorker.js', import.meta.url));
        const beachWorkerPromise = new Promise((resolve, reject) => {
            BeachWorker.onmessage = async (event) => {
                if (event.data.status === 'error') {
                    reject(new Error('Unknown Error'));
                } else {
                    const beaches = event.data.beaches;
                    const weatherBatchSize = 10;
                    for (let i = 0; i < beaches.length; i += weatherBatchSize) {
                        const batch = beaches.slice(i, i + weatherBatchSize);
                        await fetchWeather(batch);
                    }
                    if (event.data.statusComplete) {
                        try {
                            resolve(beaches);
                        } catch (error) {
                            reject(error);
                        }
                    }
                }
            };
        });

        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
                BeachWorker.terminate();
                reject(new Error('Timeout'));
            }, 60000);
        });

        Promise.race([beachWorkerPromise, timeoutPromise])
            .then(beaches => {
                setError(null);
                setBeaches(beaches);
                setLoadingBeaches(false);
            })
            .catch(async (error) => {
                if (error.message === 'Timeout') {
                    await confirm('Unknown Timeout Error');
                } else {
                    await confirm('Unknown Error');
                }
                setLoadingBeaches(false);
                setError(error.message); // Save any error message to state
            });

        BeachWorker.postMessage(allFilteredBeaches);
    };

    const {
        getMenuProps,
        getInputProps,
        highlightedIndex,
        setHighlightedIndex,
        getItemProps,
    } = useCombobox({
        items: inputItems,
        itemToString: (item) => (""),
        onInputValueChange: ({ inputValue }) => {
            setInputValue(inputValue.trim());
        },
        onSelectedItemChange: async ({ selectedItem }) => {
            setLoadingBeaches(true)
            if (selectedItem && !selectedItems.includes(selectedItem)) {
                selectedItem.inputType = "region";
                setSelectedItems([...selectedItems, selectedItem]);
                setInputValue("");

                try {
                    const data = await fetchBoundaries(selectedItem.id);
                    selectedItem.members = data.members;
                    selectedItem.bounds = data.bounds;
                    setBoundaryStatus((prevStatus) => ({ ...prevStatus, [selectedItem.id]: data.status }));
                } catch (error) {
                    setBoundaryStatus((prevStatus) => ({ ...prevStatus, [selectedItem.id]: "failure" }));
                }
            }
            setLoadingBeaches(false)
        },
    });

    return (
        <>
            <Helmet>
                <title>Search | Where Is The Weather</title>
                <meta name="description" content="Find the best beaches | Where is the Weather" />
                <meta name="keywords" content="Find the best beaches,Where is the Weather,whereistheweather" />
                <link rel="canonical" href="https://www.whereistheweather.com/search" />
            </Helmet>

            <ThemeProvider theme={theme}>
                {popupContent && (
                    <div className={styles.popup}>
                        {popupContent}
                        <button onClick={() => setPopupContent(null)}>Close</button>
                    </div>
                )}
                <div className={styles.container}>

                    {isMapVisible
                        ?
                        <MapCanvas
                            discard={() => setIsMapVisible(!isMapVisible)}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                        />
                        :
                        <div>
                            <RegionSearch
                                styles={styles}
                                getComboboxProps={styles}
                                getInputProps={getInputProps}
                                getMenuProps={getMenuProps}
                                getItemProps={getItemProps}
                                setInputFocused={setInputFocused}
                                setHighlightedIndex={setHighlightedIndex}
                                inputFocused={inputFocused}
                                loading={loading}
                                inputItems={inputItems}
                                highlightedIndex={highlightedIndex}
                            />
                            <button className={styles.drawBoundaryButton} onClick={toggleMapVisibility}>
                                OR DRAW YOUR OWN REGION ON A MAP
                            </button>
                        </div>
                    }

                    <br></br>
                    <Typography variant="h5" className={styles.sectionHeader}>Selected Regions</Typography>

                    <RegionList
                        styles={styles}
                        selectedItems={selectedItems}
                        removeSelectedItem={removeSelectedItem}
                        boundaryStatus={boundaryStatus}
                    />

                    <ForecastDatePicker
                        styles={styles}
                        selectedDate={selectedDate}
                        handleDateChange={handleDateChange}
                        setForecastDay={setForecastDay}
                        today={today}
                    />

                    <button className={styles.searchButton} onClick={searchBeaches} disabled={loadingBeaches}>
                        {loadingBeaches ? 'Loading' : 'Search Beaches'}
                    </button>

                    <Typography variant="h5" className={styles.sectionHeader}>
                        <div className={styles.centerContainer}>
                            Search Results
                            {system && (
                                <TemperatureUnitToggle />
                            )}
                        </div>
                    </Typography>

                    <div className={styles.sectionHeader}>
                        <Tabs
                            value={listMode ? 0 : 1}
                            onChange={(event, newValue) => setListMode(Boolean(!newValue))}
                            centered
                        >
                            <Tab icon={<ListIcon />} />
                            <Tab icon={<MapIcon />} />
                        </Tabs>
                    </div>

                    {
                        !loadingBeaches &&
                        <>
                            {
                                listMode
                                    ?
                                    <BeachList
                                        styles={styles}
                                        beaches={beaches}
                                        calculateBeachArea={calculateBeachArea}
                                        generateGoogleMapsLink={generateGoogleMapsLink}
                                        forecastDay={forecastDay}
                                    />
                                    :
                                    <BeachMap
                                        beaches={beaches}
                                        forecastDay={forecastDay}
                                    />
                            }
                        </>}

                </div>
            </ThemeProvider>
        </>
    );

}

export default SearchPlaces;