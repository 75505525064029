import React, { useState, useEffect } from 'react';

const Compass = ({ direction, cx, cy }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const svgSize = isMobile ? 15 : 20;
    const fontSize = isMobile ? 30 : 40;

    return (
        <svg x={cx - svgSize / 2} y={cy - svgSize / 2} width={svgSize} height={svgSize} viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" stroke="black" stroke-opacity="0.5" strokeWidth="3" fill="transparent" />
            <text x="50" y="30" textAnchor="middle" fontSize={fontSize}>N</text>
            <polygon points="50,25 40,75 60,75" fill="red"
                transform={`rotate(${direction-180} 50 50)`} />
        </svg>
    );
};

export default Compass;
