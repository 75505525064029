import React from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const SearchPlaces = ({ styles, selectedItems, removeSelectedItem, boundaryStatus }) => {
    return (
        <ul className={styles.selectedList}>
            {selectedItems.length > 0 ? (
                selectedItems.map((item, index) => (
                    item.inputType === "region"
                        ?
                        <li key={index} className={styles.selectedItem} style={{ '--i': index }}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">
                                        {`${item.tags["wikipedia"] ? item.tags["wikipedia"].split(':')[1] : item.tags["name"]}${item.extra ? ` (${item.extra})` : ''}`}
                                        {boundaryStatus[item.id] === "success" ? <CheckIcon /> : boundaryStatus[item.id] === "failure" ? <ErrorIcon /> : ""}
                                    </Typography>
                                    <IconButton onClick={() => removeSelectedItem(item)}><DeleteIcon /></IconButton>
                                </CardContent>
                            </Card>
                        </li>
                        :
                        <li key={index} className={styles.selectedItem} style={{ '--i': index }}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">
                                        {item.name}
                                        <CheckIcon />
                                    </Typography>
                                    <IconButton onClick={() => removeSelectedItem(item)}><DeleteIcon /></IconButton>
                                </CardContent>
                            </Card>
                        </li>
                ))
            ) : (
                <li>No regions selected yet.</li>
            )}
        </ul>
    );
};

export default SearchPlaces;
