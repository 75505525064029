import React, { useEffect, useLayoutEffect, useState, useRef, useMemo, useContext } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import styles from './css/BeachMap.module.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Card, CardContent, IconButton } from '@mui/material';
import { getHourlyWeather, getMax, units, convertMeasurement, formatNumber } from '../utils/utils';
import { UserPreferenceContext } from '../UserPreferenceContext';

const BeachMap = ({ beaches, forecastDay }) => {
    const [selectedBeachIndex, setSelectedBeachIndex] = useState(0);
    const [markers, setMarkers] = useState([]);
    const mapRef = useRef(null);

    const { system } = useContext(UserPreferenceContext);


    const AddCircles = useMemo(() => {
        const AddCirclesComponent = () => {
            const map = useMap();
            mapRef.current = map;

            useEffect(() => {
                const newMarkers = markers.length === beaches.length ? markers : [];

                if (newMarkers.length === 0) {
                    beaches.forEach((beach, index) => {
                        const forecastTemp = getMax(getHourlyWeather(forecastDay, beach.weather, "temperature_2m"));
                        const icon = L.divIcon({
                            className: styles.marker,
                            html: `<div>${formatNumber(convertMeasurement(forecastTemp, 'temperature_2m', system))}${units[system]['temperature_2m']}</div>`,
                            iconSize: [40, 40]
                        });

                        const marker = L.marker(beach.center, { icon });
                        marker.addTo(map);
                        marker.on('click', () => {
                            setSelectedBeachIndex(index);
                            map.flyTo(beach.center, 15);
                        });

                        newMarkers.push(marker);
                    });

                    setMarkers(newMarkers);
                }

                newMarkers.forEach((marker, index) => {
                    const beach = beaches[index];
                    const forecastTemp = getMax(getHourlyWeather(forecastDay, beach.weather, "temperature_2m"));
                    const icon = L.divIcon({
                        className: styles.marker,
                        html: `<div>${formatNumber(system === 'F' ? convertMeasurement(forecastTemp, 'temperature_2m', system) : forecastTemp)}${units[system]['temperature_2m']}</div>`,
                        iconSize: [40, 40]
                    });
                    marker.setIcon(icon);

                    marker.bindTooltip(`<b>${beach.tags.name}</b><br>Area: ${Number(formatNumber(system === 'F' ? convertMeasurement(beach.area, 'length', system) : beach.area)).toLocaleString('en-US', { useGrouping: true, groupSize: 3 }).replace(/,/g, ' ')} ${units[system]['length']}`);
                });

            }, [beaches, system, map, markers, forecastDay]);

            useLayoutEffect(() => {
                if (beaches.length > 0) {
                    const latLngBounds = beaches.map(beach => beach.center);
                    map.fitBounds(latLngBounds);
                }
            }, [beaches]);

            return null;
        };
        return <AddCirclesComponent />;
    }, [beaches, system, forecastDay, markers]);

    const handleNext = () => {
        const newIndex = (selectedBeachIndex + 1) % beaches.length;
        setSelectedBeachIndex(newIndex);
        mapRef.current && mapRef.current.flyTo(beaches[newIndex].center, 15);
    };

    const handlePrev = () => {
        const newIndex = (selectedBeachIndex - 1 + beaches.length) % beaches.length;
        setSelectedBeachIndex(newIndex);
        mapRef.current && mapRef.current.flyTo(beaches[newIndex].center, 15);
    };

    const selectedBeach = beaches[selectedBeachIndex];

    return (
        <div>
            {beaches.length > 0 ? (
                <Card className={styles.card}>
                    <IconButton className={styles.arrow} onClick={handlePrev}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <CardContent className={styles.cardContent}>
                        <h3>{selectedBeach.tags.name}</h3>
                        <p>Area: {Number(formatNumber(
                            system === 'F' ? convertMeasurement(selectedBeach.area, 'length', system) : selectedBeach.area
                        )).toLocaleString('en-US', { useGrouping: true, groupSize: 3 }).replace(/,/g, ' ')} {units[system]['length']}</p>
                        <p>Temperature: {formatNumber(getMax(
                            (system === 'F' ? getHourlyWeather(forecastDay, selectedBeach.weather, "temperature_2m").map(temp => convertMeasurement(temp, 'temperature_2m', system)) : getHourlyWeather(forecastDay, selectedBeach.weather, "temperature_2m"))
                        ))}{units[system]['temperature_2m']}</p>
                    </CardContent>
                    <IconButton className={styles.arrow} onClick={handleNext}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Card>
            ) : (
                <br></br>
            )}

            <MapContainer center={[51.505, -0.09]} zoom={2} className={styles.mapContainer}>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    attribution="&copy; CartoDB"
                />
                {AddCircles}
            </MapContainer>
        </div>
    );
};

export default BeachMap;
