import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import './About.css';
import aboutText from './About_22_7_7.md';
import { Helmet } from 'react-helmet';

function About() {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(aboutText)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      });
  }, []);

  return (
    <div className="aboutContainer">
      <Helmet>
        <title>About | Where Is The Weather</title>
        <meta name="description" content="Learn more about Where Is The Weather" />
        <link rel="canonical" href="https://www.whereistheweather.com/about" />
      </Helmet>
      <ReactMarkdown
        children={content}
        remarkPlugins={[gfm]}
        components={{
          h1: ({ node, children, ...props }) => <h1 className="sectionHeader" {...props}>{children}</h1>,
          h2: ({ node, children, ...props }) => <h2 className="sectionHeader" {...props}>{children}</h2>,
          h3: ({ node, children, ...props }) => <h3 className="sectionHeader" {...props}>{children}</h3>,
          h4: ({ node, children, ...props }) => <h4 className="sectionHeader" {...props}>{children}</h4>,
          h5: ({ node, children, ...props }) => <h5 className="sectionHeader" {...props}>{children}</h5>,
          h6: ({ node, children, ...props }) => <h6 className="sectionHeader" {...props}>{children}</h6>,
        }}
      />
    </div>
  );
}

export default About;
